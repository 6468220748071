import {
  Connection,
  Facility,
  Group,
  Inmate,
  Visitor,
} from "../../api/graphql";
import { GetConnectionsQuery } from "./GetConnections.generated";

export type InmateWithConnections = Pick<
  Inmate,
  "id" | "inmateIdentification" | "firstName" | "lastName" | "fullName"
> & {
  facility: Pick<Facility, "id" | "publicId" | "fullName">;
  group: Pick<Group, "id" | "name">;
  connections: (Pick<Connection, "id" | "status"> & {
    visitor: Pick<Visitor, "id" | "firstName" | "lastName" | "fullName">;
  })[];
};

export default function pivotConnectionsOnInmate(
  connections: Exclude<
    GetConnectionsQuery["currentVisitor"],
    null
  >["connections"]
) {
  const map: Record<string, InmateWithConnections> = {};
  connections.forEach((c) => {
    if (map[c.inmate.id]) {
      map[c.inmate.id].connections.push(c);
      return;
    }
    map[c.inmate.id] = {
      ...c.inmate,
      connections: [c],
    };
  });
  return Object.values(map);
}
