import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IdDocumentType } from "../../api/graphql";
import i18n from "../../i18n/i18nConfig";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { ScreenTitle } from "../../lib/typography";
import UploadIDBack from "./UploadIDBack.svg";
import UploadIDFront from "./UploadIDFront.svg";
import UploadIDSelfie from "./UploadIDSelfie.svg";
import { UploadImageInputBase } from "./UploadImageInput";
import useLabelsForDocumentType from "./useLabelsForDocumentType";

export type UploadPhotosStepData = {
  idImageOne?: File;
  idImageTwo?: File;
  idImageThree?: File;
};

export type IdDocumentTypeWithoutBirthCertificate = Exclude<
  IdDocumentType,
  IdDocumentType.BirthCertificate
>;

type Props = {
  documentType: IdDocumentTypeWithoutBirthCertificate;
  defaultValues: Partial<UploadPhotosStepData>;
  onNext: (data: UploadPhotosStepData) => void;
  stepper: StepperType;
};

const DOCUMENT_TYPE_LABELS: Record<
  IdDocumentTypeWithoutBirthCertificate,
  string
> = {
  [IdDocumentType.Cnic]: i18n.t("CNIC"),
  [IdDocumentType.DriversLicense]: i18n.t("driver's license"),
  [IdDocumentType.IdCard]: i18n.t("ID card"),
  [IdDocumentType.Other]: i18n.t("document"),
  [IdDocumentType.Passport]: i18n.t("passport"),
  [IdDocumentType.Undocumented]: i18n.t("face"),
};

export default function UploadPhotosStep({
  documentType,
  defaultValues,
  onNext,
  stepper,
}: Props) {
  const { t } = useTranslation();
  const [data, setData] = useState<UploadPhotosStepData>(defaultValues);
  const labelsForDocumentType = useLabelsForDocumentType();

  const isFormDisabled =
    documentType === IdDocumentType.Undocumented
      ? !data.idImageThree
      : !(data.idImageOne && data.idImageTwo && data.idImageThree);

  return (
    <StepperFormWrapper
      stepper={stepper({
        disabled: isFormDisabled,
      })}
      handleSubmit={(event) => {
        event?.preventDefault();
        onNext(data);
      }}
    >
      <ScreenTitle>
        {documentType === IdDocumentType.Undocumented
          ? t("Upload your photo")
          : t("Upload photos of your {{documentType}}", {
              documentType: DOCUMENT_TYPE_LABELS[documentType],
            })}
      </ScreenTitle>
      <Box my={2}>
        <Typography variant="body1" color="text.primary">
          {labelsForDocumentType[documentType].header.description}
        </Typography>
      </Box>
      <Grid container mt={4} mb={11}>
        {documentType !== IdDocumentType.Undocumented && (
          <>
            <Grid item xs={12}>
              <UploadImageInputBase
                id="imageOne"
                title={labelsForDocumentType[documentType].imageOne.title}
                description={
                  labelsForDocumentType[documentType].imageOne.description
                }
                value={data.idImageOne}
                placeholderImage={{
                  src: UploadIDFront,
                  alt: t("front of an identification card"),
                }}
                onChange={(value) => {
                  setData({ ...data, idImageOne: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadImageInputBase
                id="imageTwo"
                title={labelsForDocumentType[documentType].imageTwo.title}
                description={
                  labelsForDocumentType[documentType].imageTwo.description
                }
                value={data.idImageTwo}
                placeholderImage={{
                  src: UploadIDBack,
                  alt: t("back of an identification card"),
                }}
                onChange={(value) => {
                  setData({ ...data, idImageTwo: value });
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <UploadImageInputBase
            id="imageThree"
            title={labelsForDocumentType[documentType].imageThree.title}
            description={
              labelsForDocumentType[documentType].imageThree.description
            }
            value={data.idImageThree}
            placeholderImage={{
              src: UploadIDSelfie,
              alt: t(
                "person holding an identification card next to their face"
              ),
            }}
            onChange={(value) => {
              setData({ ...data, idImageThree: value });
            }}
            sx={{ borderBottom: 0 }}
          />
        </Grid>
      </Grid>
    </StepperFormWrapper>
  );
}
