import {
  Box,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import addStylesForDevices from "./lib/addStylesForDevices";
import Link from "./lib/Link";
import { onlyMobile } from "./lib/responsiveHelpers";

export type Props = {
  message: string;
  linkProps?: {
    label: string;
    to: string;
    state?: {
      fromPath?: string;
      fromName?: string;
    };
    disabled?: boolean;
  };
  header?: React.ReactNode;
  illustration: string;
  illustrationWidth: string;
};

export default function EmptyScreen({
  message,
  linkProps,
  header,
  illustration,
  illustrationWidth,
}: Props) {
  const isMobile = onlyMobile(measureScreenWidth);
  return (
    <>
      {header && header}
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <img src={illustration} alt={message} width={illustrationWidth} />
        <Typography textAlign="center">{message}</Typography>
        {linkProps && (
          <Box
            sx={addStylesForDevices(
              isMobile,
              {},
              {
                width: 1,
              }
            )}
          >
            <Link
              button
              disabled={linkProps.disabled}
              variant="contained"
              sx={addStylesForDevices(isMobile, {}, { width: 1 })}
              {...linkProps}
            >
              {linkProps.label}
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
}
