import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { OrganizationMembershipStatus } from "./api/graphql";
import AppSkeleton from "./AppSkeleton";
import { useAuthContext } from "./AuthProvider";
import { useCurrentUserData } from "./CurrentUserProvider";
import { GetCurrentUserQuery } from "./GetCurrentUser.generated";
import { AvailableCountry } from "./lib/DocumentType";
import getLanguageDirection from "./lib/languageDir";

function isSupportedUser(user: GetCurrentUserQuery["me"]) {
  if (!user) return false;
  return (
    user.__typename === "Visitor" ||
    user.__typename === "Inmate" ||
    (user.__typename === "Staff" && user.ameelio)
  );
}

export function useCurrentUser() {
  const data = useCurrentUserData();
  const me = data?.me;
  if (!me) throw new Error("Current user not found");
  if (!isSupportedUser(me))
    throw new Error(`${me.__typename} is not supported`);
  return me;
}

export function useCurrentCountry() {
  const data = useCurrentUserData();
  // Putting this check to avoid typescript error.
  if (!data) throw new Error("Current data not found");
  const { currentCountry } = data;
  return currentCountry;
}

export function useSelectableCountry() {
  const userCurrentCountry = useCurrentCountry();
  return (
    (userCurrentCountry &&
      AvailableCountry[userCurrentCountry as AvailableCountry]) ||
    AvailableCountry.US
  );
}

export function useCurrentCorrespondent() {
  const me = useCurrentUser();
  if (me.__typename !== "Visitor" && me.__typename !== "Inmate")
    throw new Error("Current user is Staff");
  return me;
}

export function useCurrentInmate() {
  const me = useCurrentUser();
  if (me.__typename !== "Inmate")
    throw new Error("Current user is not an Inmate");
  return me;
}

export function useCurrentVisitor() {
  const me = useCurrentUser();
  if (me.__typename !== "Visitor")
    throw new Error("Current user is not a Visitor");
  return me;
}

export function useCurrentLanguageDirection() {
  // To test it, we used: i18n.language === "es" ? "rtl" : i18n.dir(i18n.language)
  return getLanguageDirection();
}

export function currentMembership(user: GetCurrentUserQuery["me"]) {
  return user?.__typename === "Visitor"
    ? user.organizationMemberships.find(
        (m) => m.status === OrganizationMembershipStatus.Active
      )
    : undefined;
}

export function useCurrentMembership() {
  const visitor = useCurrentVisitor();
  const membership = currentMembership(visitor);
  if (!membership) throw new Error("Visitor has no active memberships");
  return membership;
}

export function useIsAdmin() {
  const { organizationMemberships } = useCurrentVisitor();

  return (
    !!organizationMemberships.length &&
    !!organizationMemberships.find(
      (m) =>
        m.status === OrganizationMembershipStatus.Active && m.role === "ADMIN"
    )
  );
}

export function useCurrentStaff() {
  const me = useCurrentUser();
  if (me.__typename !== "Staff") throw new Error("Current user is not a Staff");
  return me;
}

export default function SessionBoundary({
  children,
  userType,
}: {
  children: JSX.Element;
  userType?: "Inmate" | "Staff" | "Visitor";
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isLoggedIn } = useAuthContext();
  const currentUserData = useCurrentUserData();
  const me = currentUserData?.me;

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }
  if (!me) {
    return <AppSkeleton />;
  }
  if (userType && me.__typename !== userType) {
    return <Navigate to="/" />;
  }
  if (!isSupportedUser(me)) {
    // eslint-disable-next-line no-alert
    window.alert(t("Your account is not supported by this application."));
    return <Navigate to="/logout" />;
  }

  return children;
}
