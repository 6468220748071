import { PasswordInput, SubmitButton, TextInput } from "@ameelio/ui";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Link from "../lib/Link";
import { isEmail } from "../lib/validations";

export type CredentialsStepData = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  defaultValues: CredentialsStepData;
  onSubmit: (data: CredentialsStepData) => void | Promise<void>;
  disableEmailField?: boolean;
};

export default function CredentialsSignupForm({
  defaultValues,
  onSubmit,
  disableEmailField,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<CredentialsStepData>({
    mode: "onTouched",
    defaultValues,
  });

  const isMatchingPassword = (value: string) =>
    value && value === getValues("password");

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          email: data.email,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        })
      )}
    >
      <Box marginTop={2} marginBottom={6}>
        <Typography variant="body1">
          {t(
            "Welcome to Ameelio Connect! First, please enter your desired login information."
          )}
        </Typography>
      </Box>
      <Stack spacing={2} marginBottom={6}>
        <TextInput
          control={control}
          name="email"
          type="email"
          label={t("Email address")}
          rules={{
            validate: (value) =>
              isEmail(value) || t("Please enter a valid email address"),
          }}
          disabled={disableEmailField}
        />
        <PasswordInput
          control={control}
          name="password"
          label={t("Password")}
          rules={{
            required: { value: true, message: t("Password is required") },
            minLength: {
              value: 6,
              message: t(
                "Password should be at least {{length}} characters long",
                { length: 6 }
              ),
            },
          }}
          helperText={t(
            "Create a strong password with a mix of letters, numbers and symbols"
          )}
        />
        <PasswordInput
          control={control}
          name="passwordConfirmation"
          label={t("Confirm password")}
          rules={{
            validate: (value) =>
              isMatchingPassword(value) || t("Passwords must match"),
          }}
        />
      </Stack>
      <Grid container justifyContent="space-between" alignItems="center">
        <Link button to="/login" variant="outlined">
          {t("Sign in instead")}
        </Link>
        <SubmitButton submitting={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
