import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  ConnectionFeature,
  ConnectionStatus,
  CorrespondentFeature,
  FacilityService,
} from "../api/graphql";
import CallEnded from "../Call/CallEnded.svg";
import EmptyScreen from "../EmptyScreen";
import { unaccompaniedVisitAllowed } from "../Events/utils";
import buildPageTitle from "../lib/buildPageTitle";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import ProvideYourIdReminder from "../lib/ProvideYourIdReminder";
import ScreenHeader from "../lib/ScreenHeader";
import useContactRequestRejectionStatus, {
  RejectedIDAlert,
} from "../lib/useContactRequestRejectionStatus";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useRemindMinorToProvideId from "../lib/useRemindMinorToProvideId";
import { useCurrentCorrespondent } from "../SessionBoundary";
import ConnectionCard from "./ConnectionCard";
import FacilityContactsList from "./FacilityContactsList";
import { GetConnectionsDocument } from "./GetConnections.generated";
import { GetContactsScreenDataDocument } from "./GetContactsScreenData.generated";

export default function ContactsScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Contacts");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();
  const rejectionStatus = useContactRequestRejectionStatus();
  const { data, error } = useQuery(GetConnectionsDocument, {
    fetchPolicy: "cache-and-network",
  });

  const { data: facilityContactsData, error: facilityContactsError } = useQuery(
    GetContactsScreenDataDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const mayInitiateVoiceCall = useFeaturePermitted(
    CorrespondentFeature.MakeVoiceCalls
  );

  const mayInitiatePhoneCall = useFeaturePermitted(
    CorrespondentFeature.MakePhoneCalls
  );

  const mayScheduleEvents = useFeaturePermitted(
    CorrespondentFeature.ScheduleMeetings
  );

  const { showProvideIdReminder, reminderDueDate } = useRemindMinorToProvideId({
    user,
  });

  const apiError = error || facilityContactsError;
  if (apiError) throw apiError;

  if (!data?.currentCorrespondent) return <ContentSkeleton />;

  // Get all connections except inactive
  const connections = data.currentCorrespondent.connections.filter(
    (c) => c.status !== ConnectionStatus.Inactive
  );

  connections.sort((a, b) =>
    `${a.contact.fullName}`.localeCompare(`${b.contact.fullName}`)
  );

  const action =
    user.__typename === "Visitor" ? (
      <Link button variant="contained" to="/contacts/add" sx={{ flexGrow: 1 }}>
        {t("Add contact")}
      </Link>
    ) : null;

  // An inmate can make a voice call if the facility
  // hasn't set the voiceCallSlots, or if there is a current voiceCallSlot.
  const currentVoiceCallSlot =
    facilityContactsData?.currentInmate?.group.currentVoiceCallSlot;
  const nextVoiceCallSlot =
    facilityContactsData?.currentInmate?.group.nextVoiceCallSlot || null;
  const userMayMakeVoiceCall =
    user.__typename === "Inmate" &&
    (!facilityContactsData?.currentInmate?.group.hasVoiceCallSlots ||
      !!currentVoiceCallSlot);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {showProvideIdReminder && (
        <ProvideYourIdReminder dueDate={reminderDueDate} />
      )}
      <ScreenHeader action={action}>{headerTitle}</ScreenHeader>
      {mayInitiatePhoneCall && (
        <Typography variant="h2">{t("Your contacts")}</Typography>
      )}
      {rejectionStatus === "rejected-id" ? (
        <Box sx={{ my: 4 }}>
          <RejectedIDAlert />
        </Box>
      ) : null}
      {connections.length ? (
        connections.map((connection) => (
          <ConnectionCard
            key={connection.id}
            mayMakeVoiceCall={userMayMakeVoiceCall}
            nextVoiceCallSlot={nextVoiceCallSlot}
            showVoiceCall={
              mayInitiateVoiceCall &&
              connection.status === ConnectionStatus.Active
            }
            showMessage={
              connection.features.includes(ConnectionFeature.SendMessages) &&
              !connection.activeRestrictions.find(
                (r) => r.service === FacilityService.Emessaging
              ) &&
              connection.status === ConnectionStatus.Active
            }
            showScheduleEvent={
              mayScheduleEvents &&
              connection.status === ConnectionStatus.Active &&
              unaccompaniedVisitAllowed(user, connection.id)
            }
            connection={connection}
          />
        ))
      ) : (
        <EmptyScreen
          message={t(
            "You don’t have any contacts yet. This is where you’ll be able to see everyone you are connected to."
          )}
          illustration={CallEnded}
          illustrationWidth="318"
        />
      )}

      {mayInitiatePhoneCall && (
        <Box mt={3}>
          <Typography variant="h2">{t("Outside resources")}</Typography>
          <FacilityContactsList facilityContacts={facilityContactsData} />
        </Box>
      )}
    </>
  );
}
