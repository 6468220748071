import { SelectInput } from "@ameelio/ui";
import { Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IdDocumentType } from "../../api/graphql";
import {
  AvailableCountry,
  availableDocumentTypesByCountry,
  getDocumentTypeLabel,
} from "../../lib/DocumentType";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { useSelectableCountry } from "../../SessionBoundary";
import ScreenHeader from "./ScreenHeader";
import UploadIDFront from "./UploadIDFront.svg";
import { IdDocumentTypeWithoutBirthCertificate } from "./UploadPhotosStep";

export type DocumentTypeStepData = {
  country: AvailableCountry;
  documentType: IdDocumentTypeWithoutBirthCertificate;
};

type Props = {
  stepper: StepperType;
  onNext: (data: DocumentTypeStepData) => void;
  documentType?: IdDocumentTypeWithoutBirthCertificate;
  forcedCountry: AvailableCountry;
};

export default function DocumentTypeStep({
  documentType,
  forcedCountry,
  onNext,
  stepper,
}: Props) {
  const { t } = useTranslation();

  const selectableCountry = useSelectableCountry();
  const documentCountry = forcedCountry || selectableCountry;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<DocumentTypeStepData>({
    mode: "onChange",
    defaultValues: {
      documentType,
      country: documentCountry,
    },
  });

  const inferDocumentTypesByCountry = (
    country: AvailableCountry = AvailableCountry.US
  ) => {
    const availableDocuments: IdDocumentType[] =
      availableDocumentTypesByCountry[country] ||
      availableDocumentTypesByCountry[AvailableCountry.US];
    return availableDocuments.map((doc) => ({
      value: doc,
      name: getDocumentTypeLabel(doc),
    }));
  };

  return (
    <StepperFormWrapper
      stepper={stepper({ loading: isSubmitting, disabled: !isValid })}
      handleSubmit={handleSubmit(onNext)}
    >
      <ScreenHeader
        title={t("Upload your ID")}
        description={t(
          "All facilities require valid forms of identification for adults 18 years and older to approve contact requests."
        )}
      />
      <Stack my={6} spacing={6} alignItems="center">
        <img
          src={UploadIDFront}
          alt={t("front of an identification card")}
          style={{ width: "25%" }}
        />
        <SelectInput
          control={control}
          label={t("Type of ID")}
          name="documentType"
          items={inferDocumentTypesByCountry(documentCountry)}
          rules={{
            required: {
              value: true,
              message: t("Type of ID is required"),
            },
          }}
          sx={{ width: 1 }}
        />
      </Stack>
    </StepperFormWrapper>
  );
}
