import {
  Button,
  Media,
  ScreenContainer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { CheckBoxOutlined, DoNotDisturbAltOutlined } from "@mui/icons-material";
import { Chip, Link as MUILink } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentSkeleton } from "../lib/closet";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import featureTypeToHumanReadable from "../lib/featureTypeToHumanReadable";
import Link from "../lib/Link";
import ScreenSection, { ScreenSectionTitle } from "../lib/ScreenSection";
import { ScreenTitle } from "../lib/typography";
import { GetSystemDocument, GetSystemQuery } from "./GetSystem.generated";
import UpdateFacilityFeaturesDialog from "./UpdateFacilityFeaturesDialog";

const tld = (import.meta.env.VITE_API_ORIGIN || "").endsWith("org")
  ? "org"
  : "xyz";

function residentPortalDomain(code: string) {
  return `${code.toLowerCase()}-app.ameelio.${tld}`;
}

// https://developers.cloudflare.com/1.1.1.1/encryption/dns-over-https/make-api-requests/dns-json/
async function checkDNS(domain: string) {
  const data = await (
    await fetch(`https://cloudflare-dns.com/dns-query?name=${domain}`, {
      headers: { Accept: "application/dns-json" },
    })
  ).json();
  return "Status" in data && data.Status === 0;
}

export default function SystemScreen() {
  const { systemId } = useParams<{ systemId: string }>();
  if (!systemId) throw new Error("Missing system id");

  const { data, error } = useQuery(GetSystemDocument, {
    variables: { systemId },
    fetchPolicy: "cache-and-network",
  });

  // async check to see if the domain is live yet. requires engineering.
  const [domainRegistered, setDomainRegistered] = useState(false);
  useEffect(() => {
    if (!data?.system.code) return;
    checkDNS(residentPortalDomain(data.system.code)).then(setDomainRegistered);
  }, [data?.system.code]);

  const [editFacility, setEditFacility] =
    useState<GetSystemQuery["system"]["facilities"][number]>();

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { system } = data;
  const facilities = system.facilities
    .slice(0)
    .sort((a, b) => a.publicId.localeCompare(b.publicId));

  return (
    <ScreenContainer>
      {editFacility && (
        <UpdateFacilityFeaturesDialog
          facility={editFacility}
          onClose={() => setEditFacility(undefined)}
        />
      )}
      <ScreenTitle>{system.name} System</ScreenTitle>
      <ScreenSection title="Details">
        <DetailsStack>
          <DetailsStackItem label="Retention">
            {system.recordingRetention} days
          </DetailsStackItem>
          <DetailsStackItem label="Resident domain">
            <Media
              gap={0.5}
              image={
                domainRegistered ? (
                  <CheckBoxOutlined sx={{ height: 18 }} />
                ) : (
                  <Tooltip title="contact engineering to configure">
                    <DoNotDisturbAltOutlined sx={{ height: 18 }} />
                  </Tooltip>
                )
              }
            >
              <MUILink href={`https://${residentPortalDomain(system.code)}`}>
                https://{residentPortalDomain(system.code)}
              </MUILink>
            </Media>
          </DetailsStackItem>
        </DetailsStack>
      </ScreenSection>

      <section>
        <ScreenSectionTitle title="Facilities" />
        <Link to={`/toolbox/systems/${systemId}/facilities/new`}>
          Add another
        </Link>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Features</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facilities.map((f) => (
                <TableRow key={f.id}>
                  <TableCell>{f.publicId}</TableCell>
                  <TableCell>
                    {f.features
                      .map(featureTypeToHumanReadable)
                      .sort()
                      .map((feature) => (
                        <Chip label={feature} />
                      ))}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => setEditFacility(f)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </ScreenContainer>
  );
}
