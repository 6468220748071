import { useMemo } from "react";
import useMayScheduleWebinars from "../../lib/useMayScheduleWebinars";

export type WorkflowAction = "create" | "edit" | "duplicate";

type Props = {
  isWebinar: boolean;
  stepsToRemove?: Step[];
};

export type Step =
  | "selectEventType"
  | "selectWho"
  | "addGuests"
  | "selectWhen"
  | "confirm"
  | "finished";

const defaultSteps: Step[] = ["selectWhen", "confirm", "finished"];

export default function useWorkflowSteps({
  isWebinar,
  stepsToRemove,
}: Props): Step[] {
  const mayScheduleWebinars = useMayScheduleWebinars();

  // useMemo so the result can be a dependency for a useCallback
  return useMemo(() => {
    const steps: Step[] =
      mayScheduleWebinars && isWebinar
        ? ["selectEventType", "selectWho", ...defaultSteps]
        : ["selectWho", "selectEventType", "addGuests", ...defaultSteps];

    // If steps to be removed are provided, filter them out of the returned list
    if (stepsToRemove?.length)
      return steps.filter((step) => !stepsToRemove.includes(step));
    return steps;
  }, [stepsToRemove, mayScheduleWebinars, isWebinar]);
}
