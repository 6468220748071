import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import NewConnectionsScreen from "./NewConnectionsScreen";
import NewOrganizationScreen from "./NewOrganizationScreen";
import ContactDetailsScreen from "./OrganizationContacts/ContactDetailsScreen";
import OrganizationMembershipScreen from "./OrganizationMembershipScreen";
import OrganizationScreen, { OrganizationTab } from "./OrganizationScreen";

const OrganizationsRoutes: RouteObject = {
  path: "/organization/",
  element: (
    <SessionBoundary userType="Visitor">
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      path: "new",
      element: (
        <MenuLayout currentTab="organization">
          <NewOrganizationScreen />
        </MenuLayout>
      ),
    },
    {
      path: ":organizationId",
      element: (
        <ValidateUUID id="organizationId">
          <Outlet />
        </ValidateUUID>
      ),
      children: [
        {
          path: "contacts",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.contacts} />
            </MenuLayout>
          ),
        },
        {
          path: "contacts/:inmateId",
          element: (
            <MenuLayout currentTab="organization">
              <ValidateUUID id="inmateId">
                <ContactDetailsScreen />
              </ValidateUUID>
            </MenuLayout>
          ),
        },
        { path: "contacts/new", element: <NewConnectionsScreen /> },
        {
          path: "team-member/:organizationMembershipId",
          element: (
            <MenuLayout currentTab="organization">
              <ValidateUUID id="organizationMembershipId">
                <OrganizationMembershipScreen />
              </ValidateUUID>
            </MenuLayout>
          ),
        },
        {
          path: "team",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.team} />
            </MenuLayout>
          ),
        },
        {
          path: "partners",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.partners} />
            </MenuLayout>
          ),
        },
        {
          path: "info",
          element: (
            <MenuLayout currentTab="organization">
              <OrganizationScreen tab={OrganizationTab.info} />
            </MenuLayout>
          ),
        },
      ],
    },
  ],
};

export default OrganizationsRoutes;
