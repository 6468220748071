import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentSkeleton } from "../lib/closet";
import { GetConnectedFacilitiesDocument } from "./GetConnectedFacilities.generated";

type Props = {
  organizationId: string;
};

export default function OrganizationPartners({ organizationId }: Props) {
  const { t } = useTranslation();

  const { data, error } = useQuery(GetConnectedFacilitiesDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId },
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Partner name")}</TableCell>
            <TableCell>{t("Abbreviation")}</TableCell>
            <TableCell>{t("Contact")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.organization.connectedFacilities.map((cf) => (
            <TableRow key={cf.id}>
              <TableCell>
                <Typography variant="body2">{cf.fullName}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{cf.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{cf.email}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
