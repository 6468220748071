import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type FAQ = {
  question: string;
  answer: string;
};

export type FAQDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  faqs: FAQ[];
};

function FAQListItem({ question, answer }: FAQ) {
  return (
    <>
      <Box mb={1}>
        <Typography sx={{ fontWeight: "bold" }}>{question}</Typography>
      </Box>
      <Box mb={3}>
        <Typography>{answer}</Typography>
      </Box>
    </>
  );
}

export default function FAQListDialog({
  isOpen,
  onClose,
  title,
  faqs,
}: FAQDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          pr: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {faqs.map((faq) => (
          <FAQListItem key={faq.question} {...faq} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
