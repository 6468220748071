import { useQuery } from "@apollo/client";
import { Alert, Box, Link } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ContentSkeleton } from "../lib/closet";
import getHostname from "../lib/getHostname";
import { providerSupportAddress } from "../lib/supportEmailAddress";
import { GetOrganizationDetailsDocument } from "./GetOrganizationDetails.generated";

type Props = {
  organizationId: string;
};

export default function OrganizationInfo({ organizationId }: Props) {
  const { t } = useTranslation();
  const { data, error } = useQuery(GetOrganizationDetailsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId },
  });

  if (error) throw error;

  if (!data) return <ContentSkeleton />;

  const { address } = data.organization;

  return (
    <Box>
      <Alert severity="info" sx={{ my: 2 }}>
        <Trans
          t={t}
          defaults="The information listed here will be reviewed by the facilities with whom your members request contacts. If any of this information is incorrect, you can request a change by emailing <emailLink>{{supportEmailAddress}}</emailLink>."
          components={{
            emailLink: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
              <a
                href={`mailto:${providerSupportAddress}`}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
          values={{
            supportEmailAddress: providerSupportAddress,
          }}
        />
      </Alert>
      <Box sx={{ color: "text.secondary" }}>
        {t("Organization profile name")}
      </Box>
      <Box sx={{ mb: 1.75 }}>{data.organization.name}</Box>
      <Box sx={{ color: "text.secondary" }}>{t("Description")}</Box>
      <Box sx={{ mb: 1.75 }}>{data.organization.description}</Box>
      {data.organization.websiteLink && (
        <>
          <Box sx={{ color: "text.secondary" }}>{t("Website")}</Box>
          <Box sx={{ mb: 1.75 }}>
            <Link href={data.organization.websiteLink}>
              {getHostname(data.organization.websiteLink) ||
                data.organization.websiteLink}
            </Link>
          </Box>
        </>
      )}
      <Box sx={{ color: "text.secondary" }}>{t("Address")}</Box>
      <Box sx={{ mb: 1.75 }}>
        {`${address.city}, ${address.province} ${address.postalCode}, ${address.country}`}
      </Box>
      <Box sx={{ color: "text.secondary" }}>{t("Email")}</Box>
      <Box sx={{ mb: 1.75 }}>{data.organization.email}</Box>
      <Box sx={{ color: "text.secondary" }}>{t("Phone number")}</Box>
      <Box sx={{ mb: 1.75 }}>{data.organization.phone}</Box>
      <Box sx={{ color: "text.secondary" }}>{t("Logo")}</Box>
      <Box sx={{ mb: 1.75 }}>
        <img
          src={data.organization.logoImageUrl}
          width="100"
          height="100"
          alt="Logo"
        />
      </Box>
    </Box>
  );
}
