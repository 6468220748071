import { Grid, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import AppSkeleton from "../AppSkeleton";
import { useCurrentUserData } from "../CurrentUserProvider";
import Link from "../lib/Link";
import AmeelioConnect from "./AmeelioConnect.svg";
import BackToBack from "./BackToBack.svg";

export default function HomeScreen() {
  const { t } = useTranslation();

  const data = useCurrentUserData();
  // ameelio staff
  if (data?.me?.__typename === "Staff") return <Navigate to="/toolbox" />;
  // family, friends, and service providers
  if (data?.me?.__typename === "Visitor") return <Navigate to="/events" />;
  if (data.loading) return <AppSkeleton />;

  return (
    <Grid container sx={{ height: 1 }}>
      <Stack m="auto" width={300} gap={8}>
        <Stack gap={2}>
          <img src={AmeelioConnect} alt="Ameelio Connect" />
          <img
            src={BackToBack}
            alt="Two people using a phone and tablet to talk"
          />
        </Stack>

        <Stack gap={2} component="nav">
          <Link button variant="contained" to="/login">
            {t("Sign in")}
          </Link>
          <Link button variant="outlined" to="/signup">
            {t("Create account")}
          </Link>
        </Stack>
      </Stack>
    </Grid>
  );
}
