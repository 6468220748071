import { useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { Location, useLocation } from "react-router-dom";
import { ConnectionStatus, MeetingType } from "../api/graphql";
import FlowSkeleton from "../FlowSkeleton";
import useApolloErrorHandler from "../lib/handleApolloError";
import isObjectWithKey from "../lib/isObjectWithKey";
import uniqBy from "../lib/uniqBy";
import { useCurrentUser } from "../SessionBoundary";
import { GetConnectionsDocument } from "./GetConnections.generated";
import {
  GetMeetingInfoDocument,
  GetMeetingInfoQuery,
} from "./GetMeetingInfo.generated";
import SchedulingFlow, { WorkflowAction } from "./SchedulingFlow";

type LocationState = {
  fromPath: string;
  fromName: string;
  action?: WorkflowAction;
  meetingId?: string;
  connectionId?: string;
};

function isLocationState(obj: unknown): obj is LocationState {
  return (
    isObjectWithKey(obj, "fromPath") &&
    typeof obj.fromPath === "string" &&
    isObjectWithKey(obj, "fromName") &&
    typeof obj.fromName === "string" &&
    (!isObjectWithKey(obj, "action") || typeof obj.action === "string") &&
    (!isObjectWithKey(obj, "meetingId") || typeof obj.meetingId === "string") &&
    (!isObjectWithKey(obj, "connectionId") ||
      typeof obj.connectionId === "string")
  );
}

function getLocationState(location: Location): LocationState | undefined {
  if (isLocationState(location.state)) return location.state;
  return undefined;
}

export default function NewEventScreen({ webinar }: { webinar?: boolean }) {
  const location = useLocation();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const state = getLocationState(location) ?? {
    fromPath: "/events",
    fromName: t("Events"),
  };

  const onError = useApolloErrorHandler();

  const { data: connectionsData, error: connectionsError } = useQuery(
    GetConnectionsDocument,
    {
      onError,
      fetchPolicy: "cache-and-network",
    }
  );
  if (connectionsError) throw connectionsError;

  const {
    data: meetingData,
    loading: meetingLoading,
    error: meetingError,
  } = useQuery(GetMeetingInfoDocument, {
    onError,
    variables: { meetingId: state.meetingId || "" },
    skip: !state.meetingId,
  });
  if (meetingError) throw meetingError;
  const meeting = meetingData?.meeting;

  if (meetingLoading || !connectionsData?.currentVisitor)
    return <FlowSkeleton />;

  const getRegisteredGuest = (
    connection: GetMeetingInfoQuery["meeting"]["connections"][0]
  ) => ({
    id: connection.visitor.id,
    email: connection.visitor.email,
    firstName: connection.visitor.firstName,
    lastName: connection.visitor.lastName,
    fullName: connection.visitor.fullName,
    connectionId: connection.id,
  });

  const videoIpvAvailability =
    meeting && meeting.kiosk && state.action === "edit"
      ? {
          interval: meeting.interval,
          availableKiosks: [meeting.kiosk],
          conflict: false,
        }
      : undefined;

  return (
    <SchedulingFlow
      backTo={state.fromPath}
      parentName={state.fromName}
      action={state.action || "create"}
      editMeetingId={state.action === "edit" ? state.meetingId : undefined}
      connections={connectionsData.currentVisitor.connections.filter(
        (c) => c.status === ConnectionStatus.Active
      )}
      defaultValues={
        meeting?.meetingType === MeetingType.Webinar
          ? {
              meetingType: MeetingType.Webinar,
              title: meeting.title,
              connections: meeting.connections,
              availability:
                meeting && state.action !== "duplicate"
                  ? { interval: meeting.interval }
                  : null,
            }
          : meeting?.meetingType === MeetingType.VideoCall ||
              meeting?.meetingType === MeetingType.InPersonVisit
            ? {
                meetingType: meeting.meetingType,
                connections: meeting.connections,
                availability: videoIpvAvailability,
                kiosk: meeting.kiosk || undefined,
                registeredGuests: uniqBy(
                  meeting.connections
                    .filter((c) => c.visitor.id !== currentUser.id)
                    .map(getRegisteredGuest),
                  "email"
                ),
                unregisteredGuests: meeting.unregisteredGuests.map((guest) => ({
                  name: guest,
                })),
              }
            : webinar
              ? { meetingType: MeetingType.Webinar }
              : {
                  meetingType: MeetingType.VideoCall,
                  connections:
                    connectionsData.currentVisitor.connections.filter(
                      (c) => c.id === state.connectionId
                    ),
                }
      }
    />
  );
}
