import { PresentToAll, TableRestaurant, Videocam } from "@mui/icons-material";
import React from "react";
import { MeetingType } from "../api/graphql";

const IconMap: Partial<Record<MeetingType, JSX.Element>> = {
  [MeetingType.VideoCall]: <Videocam />,
  [MeetingType.InPersonVisit]: <TableRestaurant />,
  [MeetingType.Webinar]: <PresentToAll />,
};

export default function MeetingTypeIcon({
  meetingType,
}: {
  meetingType: MeetingType;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{IconMap[meetingType]}</>;
}
