import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { track } from "../analytics";
import { ConnectionStatus, FacilityFeature } from "../api/graphql";
import { TableSkeleton } from "../lib/closet";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { dateToIso } from "../lib/timeFormats";
import { ScreenTitle } from "../lib/typography";
import FacilityFeaturesDialog from "./FacilityFeaturesDialog";
import FacilityFeaturesDialogButton from "./FacilityFeaturesDialogButton";
import { SearchMinorDocument, SearchMinorQuery } from "./SearchMinor.generated";
import { MinorSearchCriteria, SearchResult } from "./types";
import useConnectionStatusTooltipCopy from "./useConnectionStatusTooltipCopy";
import useCurrentConnectionStatus from "./useCurrentConnectionStatus";

type Props = {
  stepper: StepperType;
  minorSearchCriteria: MinorSearchCriteria;
  onSubmit: (selected: SearchResult) => void | Promise<void>;
  onResultSelected: (selected: SearchResult) => void | Promise<void>;
};

export const formatMinorResult = (
  inmate?: SearchMinorQuery["minorInmate"]
): SearchResult | null => {
  if (!inmate) return null;
  return {
    id: inmate.id,
    fullName: inmate.fullName,
    facilityId: inmate.facility.id,
    facilityName: `${inmate.facility.fullName} (${inmate.facility.publicId})`,
    facilityMinimumAgeIdRequired: inmate.facility.minimumAgeIdRequired,
    facilityVisitationFormLink: inmate.facility.visitationFormLink,
    inmateIdentification: inmate.inmateIdentification,
    features: inmate.facility.features,
  };
};

export default function ConnectionsSelectOneMinorForm({
  stepper,
  minorSearchCriteria,
  onSubmit,
  onResultSelected,
}: Props) {
  const { t } = useTranslation();
  const [showFacilityFeatures, setShowFacilityFeatures] = useState(false);
  const [facilityFeaturesData, setFacilityFeaturesData] = useState<{
    name: string;
    features: FacilityFeature[];
  }>();
  const [selectedId, setSelectedId] = useState<string>();
  const {
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { data, error } = useQuery(SearchMinorDocument, {
    fetchPolicy: "network-only",
    variables: {
      ...minorSearchCriteria,
      dateOfBirth: dateToIso(new Date(minorSearchCriteria.dateOfBirth)),
    },
    onCompleted: (res) => {
      track("Contact request - Minors searched", {
        resultSize: res.minorInmate ? 1 : 0,
      });
    },
  });
  if (error) throw error;

  const inmate = formatMinorResult(data?.minorInmate);

  const currentConnectionStatus = useCurrentConnectionStatus();

  const doSubmit = async () => {
    if (!inmate || !selectedId || selectedId !== inmate.id) return;
    await onSubmit(inmate);
  };

  const getTooltipCopy = useConnectionStatusTooltipCopy();

  if (!data) return <TableSkeleton />;
  return (
    <>
      {showFacilityFeatures && (
        <FacilityFeaturesDialog
          onClose={() => {
            setShowFacilityFeatures(false);
            setFacilityFeaturesData(undefined);
          }}
          data={facilityFeaturesData}
        />
      )}
      <StepperFormWrapper
        stepper={stepper({
          loading: isSubmitting,
          disabled: !selectedId,
        })}
        handleSubmit={handleFormSubmit(doSubmit)}
      >
        {inmate ? (
          <>
            <ScreenTitle>{t("One result found")}</ScreenTitle>
            <Typography mt={2}>
              {t("Please confirm that this is who you are looking for.")}
            </Typography>

            <Box my={6}>
              <RadioGroup
                value={selectedId}
                onChange={(_, value) => {
                  setSelectedId(value);
                  onResultSelected(inmate);
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>{t("Name")}</TableCell>
                        <TableCell>{t("Facility")}</TableCell>
                        <TableCell>{t("ID")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Tooltip
                        key={inmate.id}
                        followCursor
                        arrow
                        placement="top"
                        title={getTooltipCopy(
                          currentConnectionStatus(inmate.id)
                        )}
                      >
                        <TableRow>
                          <TableCell>
                            <Radio
                              disabled={[
                                ConnectionStatus.Active,
                                ConnectionStatus.Pending,
                              ].includes(currentConnectionStatus(inmate.id))}
                              value={inmate.id}
                            />
                          </TableCell>
                          <TableCell>{inmate.fullName}</TableCell>
                          <TableCell>
                            {inmate.facilityName}
                            <br />
                            <FacilityFeaturesDialogButton
                              onClick={() => {
                                setFacilityFeaturesData({
                                  name: inmate.facilityName,
                                  features: inmate.features,
                                });
                                setShowFacilityFeatures(true);
                              }}
                            />
                          </TableCell>
                          <TableCell>{inmate.inmateIdentification}</TableCell>
                        </TableRow>
                      </Tooltip>
                    </TableBody>
                  </Table>
                </TableContainer>
              </RadioGroup>
            </Box>
          </>
        ) : (
          <>
            <ScreenTitle>{t("No results found")}</ScreenTitle>
            <Box my={6}>
              <Alert severity="warning">
                {t("Please go back to edit your search and try again.")}
              </Alert>
            </Box>
          </>
        )}
      </StepperFormWrapper>
    </>
  );
}
