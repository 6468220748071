import { Button } from "@ameelio/ui";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  CardContent,
  CircularProgress,
  Fade,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VariableSizeList } from "react-window";
import { SystemUserStatus } from "../api/graphql";
import { IconSkeleton } from "../lib/closet";
import useApolloErrorHandler from "../lib/handleApolloError";
import getLanguageDirection from "../lib/languageDir";
import { belowLargeTablet } from "../lib/responsiveHelpers";
import { useCurrentCorrespondent } from "../SessionBoundary";
import {
  GetNotificationsDocument,
  GetNotificationsQuery,
} from "./GetNotifications.generated";
import { Notification, NotificationSkeleton } from "./Notification";
import notificationIcons from "./notificationIcons";

type NotificationNode = Exclude<
  GetNotificationsQuery["currentCorrespondent"],
  null
>["notificationEvents"]["edges"][0]["node"];

export const notificationsPerPage = 20;

export function NotificationsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onError = useApolloErrorHandler();
  const [notifications, setNotifications] = useState<NotificationNode[]>();
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [checkedForNew, setCheckedForNew] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [listHeight, setListHeight] = useState<number>();

  // how much viewport space to maintain below the list
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const bottomSpace = isMobileOrSmallTablet ? 16 : 64;

  const currentUser = useCurrentCorrespondent();

  const [checkNew] = useLazyQuery(GetNotificationsDocument, {
    onError,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  const { data, loading, fetchMore } = useQuery(GetNotificationsDocument, {
    onError,
    variables: {
      perPage: notificationsPerPage,
      cursor: undefined,
      after: undefined,
    },
  });

  const listRef = useRef<VariableSizeList>(null);
  const heightsMap = useRef<Record<number, number>>({});
  const setHeight = useCallback((index: number, height: number) => {
    heightsMap.current = { ...heightsMap.current, [index]: height };
    setListHeight(Object.values(heightsMap.current).reduce((p, c) => p + c, 0));
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index: number) => heightsMap.current[index] || 0;

  const checkNewOnce = useCallback(
    (endCursor: string | undefined) => {
      if (checkedForNew) return;
      checkNew({
        variables: {
          perPage: 999,
          after: endCursor,
          cursor: undefined,
        },
      });
      setCheckedForNew(true);
    },
    [checkNew, checkedForNew, setCheckedForNew]
  );

  useEffect(() => {
    setLoadingMore(false);
    const reversedSet = data?.currentCorrespondent?.notificationEvents.edges
      .map((e) => e.node)
      .reverse();
    setNotifications(reversedSet);
    setCanLoadMore(
      data?.currentCorrespondent?.notificationEvents?.pageInfo
        ?.hasPreviousPage || false
    );
    const endCursor =
      data?.currentCorrespondent?.notificationEvents?.pageInfo?.endCursor;
    if (endCursor || reversedSet?.length === 0) checkNewOnce(endCursor);
  }, [data, checkNewOnce, setNotifications]);

  return (
    <CardContent
      ref={elementRef}
      sx={{
        whiteSpace: "nowrap",
        padding: "0 !important",
      }}
      dir={getLanguageDirection()}
    >
      {loading && (notifications?.length || 0) < 1 && (
        <Fade
          in={!data}
          timeout={{ enter: 250, exit: 0 }}
          style={{ transitionDelay: "150ms" }}
        >
          <Box>
            <NotificationSkeleton />
            <br />
            <NotificationSkeleton />
            <br />
            <NotificationSkeleton />
            <br />
          </Box>
        </Fade>
      )}
      {!loading && (notifications?.length || 0) < 1 && (
        <Typography
          variant="body1"
          sx={{ textAlign: "center", py: 3, color: "text.secondary" }}
        >
          {t("You do not have any notifications")}
        </Typography>
      )}
      {notifications && (
        <VariableSizeList
          ref={listRef}
          height={Math.min(
            listHeight || 0,
            (window?.innerHeight || Infinity) -
              // space from container to top of viewport
              (elementRef.current?.getBoundingClientRect().top || 0) -
              // space from container to bottom of viewport
              bottomSpace
          )}
          itemCount={notifications.length}
          itemSize={(index) => getSize(index)}
          estimatedItemSize={100}
          width="100%"
          style={{ borderRadius: 16, direction: getLanguageDirection() }}
        >
          {({ index, style }) => {
            const n = notifications[index];
            const icon = n.object.__typename
              ? notificationIcons[n.verb]?.[n.object.__typename]
              : null;
            const clickUrl = n.object.__typename
              ? {
                  Connection:
                    "organizationMembership" in n.object &&
                    !!n.object.organizationMembership
                      ? currentUser.__typename === "Inmate"
                        ? `/contacts/${n.object.id}`
                        : `/organization/${n.object.organizationMembership.organization.id}/contacts/${n.object.inmate.id}`
                      : `/contacts/${n.object.id}`,
                  Meeting: `/events/${n.object.id}`,
                  Message: `/messages`, // TODO - link to specific message
                  Organization: `/organization/${n.object.id}/team`,
                  SystemUser:
                    n.object.__typename === "SystemUser" &&
                    n.object.status === SystemUserStatus.Rejected
                      ? `/settings`
                      : null,
                }[n.object.__typename]
              : null;

            let notifItem = (
              <Notification
                icon={icon || <IconSkeleton />}
                title={n.title}
                body={n.body}
                isRead={n.isRead}
                created={n.createdAt}
                onClick={() => {
                  if (clickUrl) {
                    navigate(clickUrl);
                  }
                }}
                index={index}
                setHeight={setHeight}
              />
            );

            if (
              n.id === notifications[notifications.length - 1].id &&
              canLoadMore
            ) {
              notifItem = (
                <>
                  {notifItem}
                  <Box
                    sx={{
                      display: "flex",
                      py: 1,
                      px: 2,
                      cursor: "pointer",
                      backgroundColor: n.isRead ? grey[50] : "white",
                      justifyContent: "center",
                    }}
                  >
                    {!loadingMore && (
                      <Button
                        onClick={() => {
                          setLoadingMore(true);
                          fetchMore({
                            variables: {
                              cursor:
                                data?.currentCorrespondent?.notificationEvents
                                  .pageInfo.startCursor,
                            },
                          });
                        }}
                      >
                        {t("Load more")}
                      </Button>
                    )}
                    {loadingMore && <CircularProgress size={30} />}
                  </Box>
                </>
              );
            }
            return (
              <div key={n.id} style={style}>
                {notifItem}
              </div>
            );
          }}
        </VariableSizeList>
      )}
    </CardContent>
  );
}
