import { TFunction } from "react-i18next";
import { InstantReply } from "../api/graphql";

export default function getInstantReplyContent(
  t: TFunction<"translation", undefined>,
  contentEnum: InstantReply
) {
  const lookup: Record<InstantReply, string> = {
    CALL_BACK_NOW: t("I'm free now, call me back."),
    CALL_BACK_TWO_MINUTES: t("Can you call back in 2 minutes?"),
    CALL_BACK_FIVE_MINUTES: t("Can you call back in 5 minutes?"),
    CANT_TALK_NOW: t("Sorry can't talk now."),
  };
  return lookup[contentEnum];
}
