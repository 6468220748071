import { MeetingType } from "../api/graphql";
import i18n from "../i18n/i18nConfig";

const Labels: Record<MeetingType, string> = {
  [MeetingType.InPersonVisit]: i18n.t("Visit"),
  [MeetingType.VideoCall]: i18n.t("Video call"),
  [MeetingType.VoiceCall]: i18n.t("Voice call"),
  [MeetingType.Webinar]: i18n.t("Webinar"),
};

export default function meetingTypeLabel(mt: MeetingType) {
  return Labels[mt];
}
