import { IconButton, useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { AddAPhoto, Send, UploadFile } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MessageType } from "../api/graphql";
import featureEnabled from "../lib/featureEnabled";
import useApolloErrorHandler from "../lib/handleApolloError";
import useFileUpload from "../lib/useFileUpload";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { CreateMessageDocument } from "./CreateMessage.generated";
import DocumentUploadPreview from "./DocumentUploadPreview";
import { GetTimelineEndCursorDocument } from "./GetTimelineEndCursor.generated";
import ImageUploadPreview from "./ImageUploadPreview";

type Props = {
  connectionId: string;
  disabled?: boolean;
};

type FormData = {
  message: string;
};

type UploadStateData = {
  file: File;
  type: MessageType;
};

const ALLOWED_CHARACTERS =
  /^[\x20-\x7E|àèòùáéíóúñÑÁÉÍÓÚ¿¡ôêû|(?<=\w)['"’”\]]*$/m;

export default function SendMessageForm({ connectionId, disabled }: Props) {
  const { t } = useTranslation();
  const upload = useFileUpload();
  const user = useCurrentCorrespondent();
  const [uploadData, setUploadData] = useState<UploadStateData | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { isSubmitting, isSubmitSuccessful, isValid, isDirty },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      message: "",
    },
  });

  const snackbarContext = useSnackbarContext();
  const onError = useApolloErrorHandler();
  const [createMessage] = useMutation(CreateMessageDocument, {
    onError,
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    setFocus("message");
    reset();
  }, [isSubmitSuccessful, reset, setFocus]);

  // updating the apollo cache relies on a "load newer" strategy and
  // thus needs the current endCursor. this is better than manual
  // cache maintenance because the complexities of pagination add a
  // lot of edge cases and it's better to sync from the server.
  //
  // it would be nice if we could get the endCursor as a prop but
  // the current component structure makes this a peer of the normal
  // owner of that information. maybe a different day.
  //
  // it would also be nice if we didn't immediately send a duplicate
  // "load newer" query after receiving an updateConnectionTimeline
  // server event, but it's a race condition:
  //
  // 1. initiate mutation
  // 2. receive updateConnectionTimeline event and initiate update
  // 3. receive mutation response
  // 4. receive update response
  //
  // and finally, we could just wait for updateConnectionTimeline
  // to see our message appear but that extra round trip's latency
  // feels bad. also it's a distant effect and this mutation should
  // be in control of its own effects.
  const endCursorResult = useQuery(GetTimelineEndCursorDocument, {
    fetchPolicy: "cache-only",
    variables: { connectionId },
  });

  const canUploadFile = (user.availableUploadTypes || []).length > 1;

  const doSubmit = async (data: FormData) => {
    const endCursor =
      endCursorResult.data?.connection.timelineEvents.pageInfo.endCursor;
    await createMessage({
      variables: {
        input: { connectionId, content: data.message },
        after: endCursor || "",
      },
    });
  };

  const submitFile = async (file: File) => {
    try {
      const fileKey = await upload(file);
      const endCursor =
        endCursorResult.data?.connection.timelineEvents.pageInfo.endCursor;
      await createMessage({
        variables: {
          input: {
            connectionId,
            fileKey,
            fileName: file.name,
          },
          after: endCursor || "",
        },
        onError: () => {
          snackbarContext.alert("error", t("Error while uploading the file"));
        },
      });
    } catch (e) {
      snackbarContext.alert("error", t("Error while uploading the file"));
    }
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png", ".heic"],
      },
      maxFiles: 1,
      onDropAccepted: async ([file]) => {
        setUploadData({
          file,
          type: MessageType.Photo,
        });
      },
    });

  const { getRootProps: getPdfRootProps, getInputProps: getPdfInputProps } =
    useDropzone({
      accept: {
        "application/pdf": [".pdf"],
      },
      maxFiles: 1,
      onDropAccepted: async ([file]) => {
        setUploadData({
          file,
          type: MessageType.Document,
        });
      },
    });

  return (
    <>
      {uploadData && (
        <Box sx={{ padding: 1, backgroundColor: grey[50] }}>
          {uploadData?.type === MessageType.Document && (
            <Box>
              <DocumentUploadPreview
                file={uploadData.file}
                onClose={() => setUploadData(null)}
              />
            </Box>
          )}
          {uploadData?.type === MessageType.Photo && (
            <Box>
              <ImageUploadPreview
                file={uploadData.file}
                onClose={() => setUploadData(null)}
              />
            </Box>
          )}
        </Box>
      )}

      <form onSubmit={handleSubmit(doSubmit)}>
        <Box
          sx={{ display: "flex", alignItems: "flex-start" }}
          pt={1}
          px={2}
          pb={2}
        >
          <Controller
            control={control}
            name="message"
            rules={{
              required: {
                value: true,
                message: t("Create your message before sending"),
              },
              pattern: {
                value: ALLOWED_CHARACTERS,
                message: t(
                  "Due to facility restrictions, emoji and special characters are not allowed."
                ),
              },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                error={!uploadData && !!fieldState.error}
                label={t("Message")}
                variant="outlined"
                helperText={
                  !uploadData &&
                  (fieldState.error?.message ||
                    t(
                      "Facility staff can access messages you send and receive"
                    ))
                }
                InputProps={{
                  autoComplete: "off",
                  sx: {
                    borderRadius: "8px",
                  },
                }}
                sx={{ flexGrow: 1 }}
                disabled={disabled || isSubmitting || !!uploadData}
              />
            )}
          />
          {canUploadFile && featureEnabled("MEDIA_SHARING") && (
            <IconButton
              type="button"
              disabled={isSubmitting}
              color="primary"
              {...getImageRootProps()}
              ariaLabel={t("Upload photo")}
              sx={{ mt: 1, ml: 1 }}
            >
              <AddAPhoto />
              <input {...getImageInputProps()} aria-hidden />
            </IconButton>
          )}
          {canUploadFile && featureEnabled("DOCUMENT_SHARING") && (
            <IconButton
              type="button"
              disabled={isSubmitting}
              color="primary"
              {...getPdfRootProps()}
              ariaLabel={t("Upload PDF")}
              sx={{ mt: 1, ml: 1 }}
            >
              <UploadFile />
              <input {...getPdfInputProps()} aria-hidden />
            </IconButton>
          )}
          {!uploadData && (
            <IconButton
              type="submit"
              ariaLabel={t("Send")}
              disabled={disabled || isSubmitting}
              color={isDirty && isValid ? "primary" : undefined}
              sx={{ mt: 1, ml: 1 }}
            >
              <Send />
            </IconButton>
          )}
          {uploadData && (
            <IconButton
              type="button"
              ariaLabel={t("Upload")}
              disabled={disabled || isSubmitting}
              color="primary"
              sx={{ mt: 1, ml: 1 }}
              onClick={async () => {
                if (uploadData?.file) {
                  await submitFile(uploadData.file);
                  setUploadData(null);
                }
              }}
            >
              <Send />
            </IconButton>
          )}
        </Box>
      </form>
    </>
  );
}
