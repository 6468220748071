import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { track } from "../analytics";
import { AvailableCountry } from "../lib/DocumentType";

import useApolloErrorHandler from "../lib/handleApolloError";
import {
  SearchInmatesDocument,
  SearchInmatesQuery,
} from "./SearchInmates.generated";
import { MultipleSearchCriteria, SearchCriteria, SearchResult } from "./types";

const formatResult = (
  inmate: SearchInmatesQuery["adultInmates"]["results"][number]
): SearchResult => ({
  id: inmate.id,
  fullName: inmate.fullName,
  facilityId: inmate.facility.id,
  facilityName: `${inmate.facility.fullName} (${inmate.facility.publicId})`,
  facilityVisitationFormLink: inmate.facility.visitationFormLink,
  facilityMinimumAgeIdRequired: inmate.facility.minimumAgeIdRequired,
  inmateIdentification: inmate.inmateIdentification,
  features: inmate.facility.features,
});

function isBatchSearch(
  search: SearchCriteria
): search is MultipleSearchCriteria {
  return "idNumbers" in search && !!search.idNumbers;
}

export function parseIdNumbers(idNumberString: string) {
  return idNumberString
    .split(/[ ,\t\n]+/)
    .filter((s, i, a) => s.trim() !== "" && a.indexOf(s) === i);
}

export function useInmateSearch(search: SearchCriteria) {
  const handleApolloError = useApolloErrorHandler();
  const [loading, setLoading] = useState(true);

  const [searchInmates, { data, error }] = useLazyQuery(SearchInmatesDocument, {
    fetchPolicy: "network-only",
    onError: (e) => handleApolloError(e),
    onCompleted: (res) => {
      track("Contact request - Adults searched", {
        resultSize: res.adultInmates.results.length,
        minorsOmitted: res.adultInmates.minorsOmitted,
        countryFilter: search.country,
        provinceFilter: search.province,
        facilityFilter: "facilityId" in search ? search.facilityId : undefined,
      });
    },
  });

  useEffect(() => {
    setLoading(true);
    searchInmates({
      variables: isBatchSearch(search)
        ? {
            province: search.province || undefined,
            country: search.country || AvailableCountry.US,
            inmateIds: parseIdNumbers(search.idNumbers),
            lastNameOrInmateId: undefined,
            facilityId: undefined,
          }
        : {
            province: search.province || undefined,
            country: search.country || AvailableCountry.US,
            inmateIds: undefined,
            lastNameOrInmateId: search.lastNameOrInmateId,
            facilityId: search.facilityId,
          },
    });
  }, [search, searchInmates]);

  const results = useMemo(() => {
    const result =
      data?.adultInmates.results.map((res) => formatResult(res)) || [];
    setLoading(!data);
    return result;
  }, [data]);

  return {
    results,
    minorsOmitted: data?.adultInmates.minorsOmitted,
    loading,
    error,
  };
}
