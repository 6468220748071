import {
  Button,
  ScreenContainer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OrganizationMembershipRole } from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import useApolloErrorHandler from "../lib/handleApolloError";
import ScreenHeader from "../lib/ScreenHeader";
import CreateOrganizationMembershipForm from "./CreateOrganizationMembershipForm";
import { GetOrganizationMembershipsDocument } from "./GetOrganizationMemberships.generated";
import { UpdateOrganizationMembershipDocument } from "./UpdateOrganizationMembership.generated";

export default function OrganizationMembersScreen() {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("missing id param");

  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const { data, error } = useQuery(GetOrganizationMembershipsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId: id },
  });

  const [updateOrganizationMembership] = useMutation(
    UpdateOrganizationMembershipDocument,
    {
      onError: (e) => handleApolloError(e),
    }
  );

  const updateRole = (
    organizationMembershipId: string,
    role: OrganizationMembershipRole
  ) => {
    updateOrganizationMembership({
      variables: { input: { organizationMembershipId, role } },
    });
  };

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { memberships } = data.organization;

  return (
    <ScreenContainer>
      <ScreenHeader hideNotifications>{t("Members")}</ScreenHeader>
      <CreateOrganizationMembershipForm organizationId={id} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.map((m) => (
              <TableRow key={m.id}>
                <TableCell>{m.visitor.email}</TableCell>
                <TableCell>
                  {m.role}{" "}
                  <Button
                    onClick={() =>
                      updateRole(
                        m.id,
                        m.role === "ADMIN"
                          ? OrganizationMembershipRole.Member
                          : OrganizationMembershipRole.Admin
                      )
                    }
                  >
                    {m.role === "ADMIN"
                      ? "Demote to member"
                      : "Promote to admin"}
                  </Button>
                </TableCell>
                <TableCell>{m.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ScreenContainer>
  );
}
