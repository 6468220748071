import { uniq } from "@ameelio/core";
import { SelectInput, SelectInputBase, SubmitButton } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import buildPageTitle from "../lib/buildPageTitle";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import { ScreenTitle } from "../lib/typography";
import {
  GetFacilitiesDocument,
  GetFacilitiesQuery,
} from "./GetFacilities.generated";

export type SelectFacilityData = {
  selectedFacilityId: "";
};

function FacilitySelectScreen() {
  const { t } = useTranslation();

  const handleApolloError = useApolloErrorHandler();

  const navigate = useNavigate();
  const location = useLocation();
  const from = getFrom(location);
  const [selectedProvince, setSelectedProvince] = useState<string>("");

  const { data, error } = useQuery(GetFacilitiesDocument, {
    onError: (e) => handleApolloError(e),
  });

  if (error) throw error;

  const facilities = data?.facilities || [];
  const uniqueProvinces = uniq(
    facilities.map((facility) => `${facility.province}`)
  );
  // We create a map although it seems unlikely that we will have the same province name in different countries...
  const stateToCountryMap: { [key: string]: string } = {};
  facilities.forEach((f) => {
    stateToCountryMap[f.province] = f.country;
  });

  const facilityMap: {
    [id: string]: GetFacilitiesQuery["facilities"][number];
  } = {};
  facilities.forEach((facility) => {
    facilityMap[facility.id] = facility;
  });

  const {
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<SelectFacilityData>({
    mode: "onChange",
  });

  const headerTitle = t("authentication.FacilitySelectScreen.choose_facility");
  const pageTitle = buildPageTitle(headerTitle);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenTitle>{headerTitle}</ScreenTitle>
      <Typography sx={{ marginTop: 2 }}>
        {t("authentication.FacilitySelectScreen.choose_facility_desc")}
      </Typography>
      <form
        onSubmit={handleFormSubmit((formData) =>
          navigate(
            `/login/${facilityMap[
              formData.selectedFacilityId
            ].system.code.toLowerCase()}`,
            {
              state: { from },
            }
          )
        )}
      >
        <Stack spacing={2} sx={{ marginY: 6 }}>
          <SelectInputBase
            name="state"
            label={t("authentication.FacilitySelectScreen.state_province")}
            value={selectedProvince}
            defaultValue=""
            onChange={(event) =>
              setSelectedProvince(event.target.value as string)
            }
            items={uniqueProvinces.map((province) => ({
              value: province,
              name: `${province} (${stateToCountryMap[province]})`,
            }))}
          />
          <SelectInput
            name="selectedFacilityId"
            label={t("authentication.FacilitySelectScreen.facility")}
            defaultValue=""
            control={control}
            rules={{ validate: (v) => !!v }}
            items={facilities
              .filter((facility) => facility.province === selectedProvince)
              .map((facility) => ({
                value: facility.id,
                name: facility.name,
              }))}
          />
        </Stack>
        <Grid container justifyContent="flex-end">
          <SubmitButton submitting={isSubmitting} disabled={!isValid}>
            {t("authentication.FacilitySelectScreen.continue")}
          </SubmitButton>
        </Grid>
      </form>
    </>
  );
}

export default FacilitySelectScreen;
