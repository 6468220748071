import {
  ScreenContainer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import React from "react";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import ScreenHeader from "../lib/ScreenHeader";
import { GetSystemsDocument } from "./GetSystems.generated";

export default function SystemsScreen() {
  const { data, error } = useQuery(GetSystemsDocument, {
    fetchPolicy: "cache-and-network",
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  return (
    <ScreenContainer>
      <ScreenHeader
        hideNotifications
        action={
          <Link
            button
            variant="outlined"
            to="/toolbox/systems/new"
            sx={{ flexGrow: 1 }}
          >
            Add system
          </Link>
        }
      >
        Systems
      </ScreenHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.systems.map((s) => (
              <TableRow key={s.id}>
                <TableCell>{s.name}</TableCell>
                <TableCell>{s.code}</TableCell>
                <TableCell>
                  <Link to={`/toolbox/systems/${s.id}`}>Inspect</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ScreenContainer>
  );
}
