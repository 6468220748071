// Intended to match https://github.com/FreedomBen/malan/blob/9e3be0821ef0771ec9829f50146f36ae28296b09/lib/malan/accounts/user.ex#L278-L288
// eslint-disable-next-line import/prefer-default-export
import { differenceInYears } from "date-fns";
import i18n from "../i18n/i18nConfig";

export function isEmail(value: string): boolean {
  const email = String(value).toLowerCase();
  if (email.length < 6 || email.length > 150) return false;
  if (/@.*@/.test(email)) return false;
  if (/^\|/.test(email)) return false;
  return /^[!#$%&'*+-/=?^_`{|}~A-Za-z0-9]{1,64}@[.-A-Za-z0-9]{1,63}\.[A-Za-z]{2,25}$/.test(
    email
  );
}

export function isFullName(val: string) {
  return val.length > 0 && val.split(" ").length > 1;
}

export const MAX_USER_AGE = 129;
export const MIN_USER_AGE = 13;

/*
  Verifies correct date of birth and provides info on the error
 */
export function validateDateOfBirth(value?: Date) {
  const invalidDate = i18n.t("Please enter a valid date");
  // Invalid data (or value = undefined - though I believe the undefined case
  // will be handled by required)
  if (!value || value?.toString() === "Invalid Date") return invalidDate;

  const now = new Date();
  if (value.getTime() > now.getTime())
    return i18n.t("Please enter a date in the past");

  // Older than oldest age ever recorded
  const age = differenceInYears(new Date(), value);
  if (age > MAX_USER_AGE || age <= 0) return invalidDate;

  // Younger than allowed
  if (age < MIN_USER_AGE)
    return i18n.t(
      "You must be at least {{minUserAge}} years old to have an Ameelio Connect account",
      { minUserAge: MIN_USER_AGE }
    );
  // Checks passed
  return true;
}

export const MAX_PHONE_NUMBER_LENGTH = 20;

export const ALLOWED_PHONE_NUMBER_CHARS = /^(\d|\(|\)|-|\+|\s)+$/;

export enum AvailableValidator {
  PHONE_NUMBER = "PHONE_NUMBER",
}

export const getValidator = (type: AvailableValidator) => {
  switch (type) {
    case AvailableValidator.PHONE_NUMBER:
      return {
        required: {
          value: true,
          message: i18n.t("Phone number is required"),
        },
        pattern: {
          value: ALLOWED_PHONE_NUMBER_CHARS,
          message: i18n.t(
            "Phone number cannot contain letters or special characters, except for +, (, ), -"
          ),
        },
        maxLength: {
          value: MAX_PHONE_NUMBER_LENGTH,
          message: i18n.t(
            "Phone number cannot be more than {{number}} digits",
            {
              // {{number}}, not {{count}}: is always > 1
              number: MAX_PHONE_NUMBER_LENGTH,
            }
          ),
        },
      };
    default:
      throw new Error(`Unknown validator type: ${type}`);
  }
};
