import {
  Dialog,
  DialogActions,
  DialogContent,
  SelectInputBase,
  SubmitButton,
  TextInputBase,
} from "@ameelio/ui";
import { useLazyQuery } from "@apollo/client";
import { SelectChangeEvent, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "../../../api/graphql";
import useApolloErrorHandler from "../../../lib/handleApolloError";
import { SecondaryButton } from "../../../lib/ModalButtons";
import { isEmail } from "../../../lib/validations";
import { useCurrentVisitor } from "../../../SessionBoundary";
import { RegisteredGuest, UnregisteredGuest } from "../types";
import { GetInmateGuestDocument } from "./GetInmateGuest.generated";

type Props = {
  meetingType: MeetingType;
  connectionId: string;
  registrationRequired: boolean;
  onAddRegisteredGuest: (g: RegisteredGuest) => void;
  onAddUnregisteredGuest: (g: UnregisteredGuest) => void;
  unregisteredGuests: UnregisteredGuest[];
  onClose: () => void;
};

export default function AddGuestDialog({
  meetingType,
  connectionId,
  registrationRequired,
  onAddRegisteredGuest,
  onAddUnregisteredGuest,
  unregisteredGuests,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const currentUser = useCurrentVisitor();

  const [addingRegisteredGuest, setAddingRegisteredGuest] = useState(true);
  const [checking, setChecking] = useState(false);
  const [guestInput, setGuestInput] = useState<string>();
  const [guestInputError, setGuestInputError] = useState<string>();

  const [getInmateGuest] = useLazyQuery(GetInmateGuestDocument, {
    onError: handleApolloError,
    fetchPolicy: "cache-first",
  });

  const checkRegisteredGuest = async (email: string) => {
    setGuestInputError(undefined);
    const trimmedEmail = email.trim();
    if (!isEmail(trimmedEmail)) {
      setGuestInputError(t("Please enter a valid email address"));
    } else {
      const { data } = await getInmateGuest({
        variables: {
          connectionId,
          visitorEmail: trimmedEmail,
        },
      });
      const guest = data?.connection.inmate.guest;
      if (!guest) {
        return setGuestInputError(
          `${t(
            "This email is not currently approved by the facility for {{meetingType}} with {{firstName}}. Check the address entered, or that the guest has requested {{firstName}} as a contact.",
            {
              meetingType:
                meetingType === MeetingType.VideoCall
                  ? t("video calls")
                  : t("visits"),
              firstName: data?.connection.inmate.firstName || t("your contact"),
            }
          )}`
        );
      }
      if (guest.id === currentUser.id) {
        return setGuestInputError(t("You cannot add yourself as a guest"));
      }
      onAddRegisteredGuest(guest);
      setGuestInput(undefined);
    }
    return true;
  };

  const checkUnregisteredGuest = async (name: string) => {
    const newGuestName = name.trim();
    if (!newGuestName) {
      setGuestInputError(t("Please enter a valid name"));
    } else if (unregisteredGuests.map((g) => g.name).includes(newGuestName)) {
      setGuestInputError(t("A guest with this name already exists"));
    } else {
      onAddUnregisteredGuest({ name: newGuestName });
      setGuestInput(undefined);
    }
  };

  const checkGuestInput = async () => {
    setGuestInputError(undefined);
    setChecking(true);
    try {
      return addingRegisteredGuest
        ? await checkRegisteredGuest(guestInput || "")
        : await checkUnregisteredGuest(guestInput || "");
    } finally {
      setChecking(false);
    }
  };

  const [touched, setTouched] = useState(false);

  const validationError = (() => {
    if (!touched) return "";
    if (guestInputError) return guestInputError;
    if (!guestInput?.trim()) {
      return addingRegisteredGuest
        ? t("Please enter a valid email address")
        : t("Please enter a valid name");
    }
    if (addingRegisteredGuest && !isEmail(guestInput?.trim())) {
      return t("Please enter a valid email address");
    }
    return "";
  })();

  return (
    <Dialog title={t("Add guest")} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">
            {registrationRequired
              ? t("Are they 13 years of age or older?")
              : t("Do they have an Ameelio Connect account?")}
          </Typography>
          <SelectInputBase
            value={addingRegisteredGuest ? "registered" : "unregistered"}
            onChange={(event: SelectChangeEvent<unknown>) => {
              setAddingRegisteredGuest(event.target.value === "registered");
              setGuestInput(undefined);
              setTouched(false);
              setGuestInputError(undefined);
            }}
            items={[
              {
                name: t("Yes"),
                value: "registered",
              },
              {
                name: t("No"),
                value: "unregistered",
              },
            ]}
          />
          <Typography variant="body2" color="text.secondary">
            {addingRegisteredGuest
              ? t(
                  "Enter the email address of the guest's approved Ameelio Connect account."
                )
              : t(
                  "Please be prepared to present government-issued identification during the visit if asked by facility staff."
                )}
          </Typography>
          <TextInputBase
            sx={{
              width: "100%",
              borderRadius: "8px",
            }}
            autoComplete="off"
            value={guestInput}
            label={
              addingRegisteredGuest
                ? t("Guest's email address")
                : t("Guest's full name")
            }
            onChange={(e) => {
              setTouched(true);
              setGuestInput(e.target.value);
              setGuestInputError(undefined);
            }}
            error={touched && !!validationError}
            helperText={validationError}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
          <SubmitButton
            disabled={!!validationError || !touched}
            submitting={checking}
            onClick={checkGuestInput}
          >
            {t("Add")}
          </SubmitButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}
