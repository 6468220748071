import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConnectionStatus, SystemUserStatus } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import RejectionAlert from "./RejectionAlert";

export default function useContactRequestRejectionStatus() {
  const user = useCurrentCorrespondent();

  if (user.__typename !== "Visitor") return null;

  return user.systemRelationships.some(
    (sr) => sr.status === SystemUserStatus.Rejected
  )
    ? "rejected-id"
    : user.connections.some((c) => c.status === ConnectionStatus.Rejected)
      ? "rejected-connection"
      : null;
}

export function RejectedIDAlert() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <RejectionAlert
      title={t(
        "You must upload new ID documents for your pending contact requests."
      )}
      onClick={() => navigate("/settings")}
    />
  );
}

export function RejectedConnectionAlert() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <RejectionAlert
      title={t("You have one or more rejected contact requests")}
      onClick={() => navigate("/contacts")}
    />
  );
}
