import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { GuestFragmentFragmentDoc } from './GuestFragment.generated';
export type GetInmateGuestQueryVariables = Types.Exact<{
  connectionId: Types.Scalars['ID'];
  visitorEmail: Types.Scalars['String'];
}>;


export type GetInmateGuestQuery = { __typename: 'Query', connection: { __typename: 'Connection', id: string, inmate: { __typename: 'Inmate', id: string, firstName: string, fullName: string, guest: { __typename: 'InmateGuest', id: string, firstName: string, lastName: string, fullName: string, email: string, connectionId: string } | null } } };


export const GetInmateGuestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInmateGuest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"visitorEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"guest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"visitorEmail"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"GuestFragment"}}]}}]}}]}}]}},...GuestFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetInmateGuestQuery, GetInmateGuestQueryVariables>;