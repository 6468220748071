import { ConfirmDialog, SubmitButton, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { appendItem } from "../client";
import useApolloErrorHandler from "../lib/handleApolloError";
import { isEmail } from "../lib/validations";
import { CreateOrganizationMembershipDocument } from "./CreateOrganizationMembership.generated";

type Props = {
  organizationId: string;
};

type FormData = {
  email: string;
};

export default function CreateOrganizationMembershipForm({
  organizationId,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: { email: "" },
  });

  const [createMembership] = useMutation(CreateOrganizationMembershipDocument, {
    onError: (e) => handleApolloError(e),
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: cache.identify({ __typename: "Organization", id: organizationId }),
        fields: {
          memberships: appendItem(
            data.createOrganizationMembership.organizationMembership
          ),
        },
      });
    },
  });

  const [confirming, setConfirming] = useState<FormData>();
  const onSubmit = (data: FormData) => {
    setConfirming(data);
  };
  const doSubmit = async (data: FormData) => {
    setConfirming(undefined);
    await createMembership({
      variables: {
        input: {
          organizationId,
          email: data.email,
        },
      },
    });
    reset();
  };

  return (
    <>
      {confirming && (
        <ConfirmDialog
          description={t(
            "If this account exists, it will be converted to an organization account by deactivating any prior connections."
          )}
          onCancel={() => setConfirming(undefined)}
          onConfirm={() => doSubmit(confirming)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} direction="row" pb={2}>
          <TextInput
            control={control}
            name="email"
            label={t("Email Address")}
            rules={{ validate: (v) => isEmail(v) }}
            size="small"
          />
          <SubmitButton submitting={isSubmitting}>{t("Add")}</SubmitButton>
        </Stack>
      </form>
    </>
  );
}
