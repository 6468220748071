import { Language } from "../api/graphql";

const languageToI18n: Record<Language, string> = {
  [Language.En]: "en",
  [Language.Es]: "es",
  [Language.It]: "it",
};

const LABELS: Record<Language, string> = {
  [Language.En]: "English",
  [Language.Es]: "Español",
  [Language.It]: "Italiano",
};

// Get the language label
function languageLabel(type: Language) {
  return LABELS[type];
}

// Check if a language is supported
function isSupportedLanguage(language: string): language is Language {
  return Object.values(languageToI18n).includes((language || "").toLowerCase());
}

// Convert browser language to i18n language. If browser language is not supported, return "en"
function browserToI18n(browserLanguage = navigator.language) {
  const prefix = (browserLanguage.split("-")[0] || "").toLowerCase();
  if (isSupportedLanguage(prefix)) return prefix;
  return "en";
}

export { languageToI18n, browserToI18n, isSupportedLanguage, languageLabel };
