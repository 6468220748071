import { SelectInput, TextInput } from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { sub, subYears } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../lib/DateInput";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { inferDateFormat, monthDateYearFormat } from "../lib/timeFormats";
import { ScreenTitle } from "../lib/typography";
import { MAX_USER_AGE } from "../lib/validations";
import { MinorSearchCriteria } from "./types";

type Props = {
  stepper: StepperType;
  facilityOptions: { name: string; value: string }[];
  defaultValues: Partial<MinorSearchCriteria>;
  onSubmit: (data: MinorSearchCriteria) => void;
};

export default function ConnectionsSearchMinorsForm({
  stepper,
  facilityOptions,
  defaultValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<MinorSearchCriteria>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      ...defaultValues,
    },
  });

  const isValidMinorBirthday = (value: string) => {
    const isInvalidDate = Number.isNaN(new Date(value).getTime());
    if (isInvalidDate) {
      return t("Please enter in the format {{format}}", {
        format: inferDateFormat().toLowerCase().replace(/-/gi, "/"),
      });
    }

    // And also that the date means the search result is for a minor
    if (subYears(new Date(), 18) > new Date(value))
      return t("Must be after {{minorCutoffDate}}", {
        minorCutoffDate: monthDateYearFormat.format(subYears(new Date(), 18)),
      });
    return true;
  };

  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: isSubmitting,
        disabled: !isValid,
      })}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <ScreenTitle>{t("Search for a minor")}</ScreenTitle>
      <Typography marginTop={2}>
        {t("Enter all of the information for the minor you are searching for.")}
      </Typography>
      <Stack marginTop={3} marginBottom={6} spacing={3}>
        <TextInput
          control={control}
          name="firstName"
          label={t("First Name")}
          sx={{ width: 1 }}
          rules={{
            required: { value: true, message: t("First name is required") },
          }}
        />
        <TextInput
          control={control}
          name="lastName"
          label={t("Last Name")}
          sx={{ width: 1 }}
          rules={{
            required: { value: true, message: t("Last name is required") },
          }}
        />
        <DateInput
          control={control}
          name="dateOfBirth"
          label={t("Date of birth")}
          disableFuture
          disableHighlightToday
          minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
          rules={{
            required: { value: true, message: t("Date of birth is required") },
            validate: isValidMinorBirthday,
          }}
        />
        <SelectInput
          control={control}
          name="facilityId"
          label={t("Facility")}
          items={facilityOptions}
          rules={{ required: true }}
        />
      </Stack>
    </StepperFormWrapper>
  );
}
