import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FullscreenFlow from "../FullscreenFlow";
import { accessibilitySupportAddress } from "../lib/supportEmailAddress";
import MessagesComingSoon from "../Messages/MessagesComingSoon.svg";

interface SupportScreenState {
  backTo: string;
  parentName: string;
}

export default function SupportScreen() {
  const location = useLocation();
  const { backTo, parentName } = location.state as SupportScreenState;
  const { t } = useTranslation();
  const headerTitle = t("Support");

  return (
    <FullscreenFlow backTo={backTo} breadcrumbs={[parentName, headerTitle]}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <img src={MessagesComingSoon} alt={headerTitle} width="275" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          gap={4}
          mb={6}
        >
          <Typography variant="h1" textAlign="center">
            {t("Need Support?")}
          </Typography>
          <Typography variant="body1" textAlign="left">
            <b>
              {t(
                "Please contact the facility for support with applying to be a visitor at the facility, and submitting contact and meeting requests for facility approval."
              )}
            </b>
          </Typography>
          <Typography variant="body1" textAlign="left">
            {t(
              "If you need technical support with using Connect, select one of the following options."
            )}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
          >
            <Button
              variant="contained"
              target="_blank"
              href="https://ameeliocustomerservice.zendesk.com/hc/en-us/categories/360004071432-Ameelio-Connect-App-Video-Voice-and-In-Person-Visitation"
              disableElevation
            >
              {t("Read customer support articles")}
            </Button>
            <Button
              variant="contained"
              target="_blank"
              href="https://ameeliocustomerservice.zendesk.com/hc/en-us/requests/new?ticket_form_id=5998041063693"
              disableElevation
            >
              {t("Submit request")}
            </Button>
          </Box>
          {/* Remove the display: none div when we're WCAG Level AA compliant */}
          <div style={{ display: "none" }}>
            <Typography variant="h1" textAlign="center">
              {t("Accessibility")}
            </Typography>
            <Typography variant="body1" textAlign="left">
              {t(
                "Ameelio is committed to ensuring digital accessibility for people with disabilities. We recommend using Ameelio Connect on a desktop or laptop with a web browser (Chrome, Safari, or Firefox), rather than on a mobile device. Ameelio Connect for web is fully conformant with Web Content Accessibility Guidelines (WCAG) 2.2 level AA."
              )}
            </Typography>
            <Typography variant="body1" textAlign="left">
              <Trans
                t={t}
                defaults="We are continually improving the user experience for everyone. Please let us know if you encounter accessibility barriers by submitting a support request or emailing <emailLink>{{accessibilitySupportAddress}}</emailLink>."
                components={{
                  emailLink: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
                    <a
                      href={`mailto:${accessibilitySupportAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
                values={{
                  accessibilitySupportAddress,
                }}
              />
            </Typography>
          </div>
        </Box>
      </Box>
    </FullscreenFlow>
  );
}
