import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationDetailsFragmentFragmentDoc } from '../api/OrganizationDetailsFragment.generated';
export type GetOrganizationDetailsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type GetOrganizationDetailsQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, name: string, type: Types.OrganizationType, description: string, websiteLink: string | null, phone: string, email: string, logoImageUrl: string, address: { __typename: 'Address', lineOne: string, lineTwo: string | null, city: string, province: string, country: string, postalCode: string } } };


export const GetOrganizationDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrganizationDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationDetailsFragment"}}]}}]}},...OrganizationDetailsFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>;