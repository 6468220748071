import { Chip, ContextMenu } from "@ameelio/ui";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {
  Box,
  Card,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { User } from "@sentry/react";
import { isWithinInterval, subMinutes } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Connection,
  Correspondent,
  Interval,
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
  Visitor,
} from "../api/graphql";
import featureEnabled from "../lib/featureEnabled";
import isMultimediaMeeting from "../lib/isMultimediaMeeting";
import Link from "../lib/Link";
import MeetingTypeIcon from "../lib/MeetingTypeIcon";
import { participantsList } from "../lib/nameUtils";
import { formatTimeRange } from "../lib/timeFormats";
import { useCurrentCorrespondent } from "../SessionBoundary";
import JoinCallButton from "./JoinCallButton";

export const LEAVABLE_MEETING_STATUSES = [
  MeetingStatus.PendingApproval,
  MeetingStatus.Scheduled,
];

type Props = {
  meeting: Pick<
    Meeting,
    | "id"
    | "title"
    | "meetingType"
    | "status"
    | "unregisteredGuests"
    | "privacyLevel"
  > & {
    interval: Pick<Interval, "startAt" | "endAt">;
    correspondents: Pick<
      Correspondent,
      "id" | "firstName" | "lastName" | "fullName"
    >[];
    connections: (Pick<Connection, "id"> & { visitor: Pick<User, "id"> })[];
    primaryVisitor?: Pick<Visitor, "id"> | null;
    visitors: Pick<Visitor, "id">[];
  };
  onCancel: (args: { description: string; meetingId: string }) => void;
  onLeaveEvent: (args: { description: string; meetingId: string }) => void;
  sx?: SxProps<Theme>;
};

export function isCallJoinable(
  meeting: Pick<Meeting, "meetingType" | "status"> & {
    interval: Pick<Interval, "startAt" | "endAt">;
  }
) {
  const now = new Date();
  const inJoinableWindow =
    isMultimediaMeeting(meeting.meetingType) &&
    isWithinInterval(now, {
      start: subMinutes(meeting.interval.startAt, 5),
      end: meeting.interval.endAt,
    });

  return (
    inJoinableWindow &&
    isMultimediaMeeting(meeting.meetingType) &&
    [MeetingStatus.Live, MeetingStatus.Scheduled].includes(meeting.status)
  );
}

export default function EventCard({
  meeting,
  onCancel,
  onLeaveEvent,
  sx,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentCorrespondent();
  const currentUserIsPrimaryVisitor =
    currentUser.id === meeting.primaryVisitor?.id;
  const now = new Date();
  const isEditable =
    [MeetingStatus.PendingApproval, MeetingStatus.Scheduled].includes(
      meeting.status
    ) && subMinutes(meeting.interval.startAt, 5) > now;
  const isWebinar = meeting.meetingType === MeetingType.Webinar;
  const isWebinarHost = isWebinar && currentUser.__typename === "Visitor";
  // All events must be in a cancelable state, only webinar hosts can cancel
  // webinars, but inmates or visitors can cancel 1-on-1 meetings
  const canCancelEvent =
    isEditable &&
    (isWebinarHost ||
      (!isWebinar &&
        (currentUserIsPrimaryVisitor || currentUser.__typename === "Inmate")));
  // anyone who can cancel can also modify except inmates
  const canModifyEvent = canCancelEvent && currentUser.__typename !== "Inmate";

  // Parsing participants
  const registeredParticipantsList = meeting.correspondents.filter(
    (c) => c.id !== currentUser.id
  );
  const unregisteredParticipantsList = meeting.unregisteredGuests || [];
  const allParticipantsList = [
    ...registeredParticipantsList.map((p) => p.fullName),
    ...unregisteredParticipantsList,
  ].join(", ");
  const meetingName = meeting.title || allParticipantsList;

  // Set the cancel meeting dialog description (if needed)
  const cancelDescription = isWebinar
    ? t(
        "This can't be undone. We will let all hosts and students know that the webinar is canceled."
      )
    : t(
        "This can't be undone. We will let {{participants}} know that the meeting is canceled.",
        { participants: participantsList(registeredParticipantsList) }
      );

  // depends on re-renders to re-evaluate. this currently
  // works because we poll for new events.

  return (
    <Card variant="outlined" sx={sx || {}}>
      <Stack padding={3} spacing={1.5}>
        <Box display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={1}
            color="primary.main"
            alignItems="center"
          >
            <MeetingTypeIcon meetingType={meeting.meetingType} />
            <Link to={`/events/${meeting.id}`}>
              <Typography
                marginLeft={1}
                variant="h3"
                sx={{ textDecoration: "underline" }}
              >
                {meetingName}
              </Typography>
            </Link>
            {meeting.status === MeetingStatus.PendingApproval && (
              <Tooltip
                arrow
                placement="top"
                title={t(
                  "This event is being reviewed right now. This can take up to 3 business days."
                )}
              >
                <span>
                  <Chip color="orange" label={t("Pending")} />
                </span>
              </Tooltip>
            )}
            {meeting.status === MeetingStatus.Rejected && (
              <Tooltip
                arrow
                placement="top"
                title={t(
                  "This event was declined by the facility. You should have received an email with the reason why the event was declined."
                )}
              >
                <span>
                  <Chip color="red" label={t("Declined")} />
                </span>
              </Tooltip>
            )}
            {meeting.status === MeetingStatus.Cancelled && (
              <span>
                <Chip color="red" label={t("Canceled")} />
              </span>
            )}
            {meeting.status === MeetingStatus.Ended && (
              <span>
                <Chip color="grey" label={t("Ended")} />
              </span>
            )}
            {meeting.status === MeetingStatus.NoShow && (
              <span>
                <Chip color="grey" label={t("No Show")} />
              </span>
            )}
          </Stack>

          <ContextMenu id={`meeting-${meeting.id}`}>
            <MenuItem onClick={() => navigate(`/events/${meeting.id}`)}>
              {t("See event details")}
            </MenuItem>
            {canModifyEvent && (
              <MenuItem
                onClick={() =>
                  navigate("/events/new", {
                    state: {
                      fromPath: "/events",
                      fromName: t("Events"),
                      action: "edit",
                      meetingId: meeting.id,
                      connectionId:
                        meeting.meetingType !== MeetingType.Webinar
                          ? meeting.connections.find(
                              (c) => c.visitor.id === currentUser.id
                            )?.id
                          : undefined,
                    },
                  })
                }
              >
                {t("Modify event")}
              </MenuItem>
            )}
            {isWebinarHost && (
              <MenuItem
                onClick={() =>
                  navigate("/events/new", {
                    state: {
                      fromPath: "/events",
                      fromName: t("Events"),
                      action: "duplicate",
                      meetingId: meeting.id,
                    },
                  })
                }
              >
                {t("Duplicate event")}
              </MenuItem>
            )}
            {canCancelEvent && (
              <MenuItem
                onClick={() =>
                  onCancel({
                    description: cancelDescription,
                    meetingId: meeting.id,
                  })
                }
                sx={{ color: "error.main" }}
              >
                {t("Cancel event")}
              </MenuItem>
            )}
            {!currentUserIsPrimaryVisitor &&
              currentUser.__typename === "Visitor" &&
              LEAVABLE_MEETING_STATUSES.includes(meeting.status) && (
                <MenuItem
                  onClick={() =>
                    onLeaveEvent({
                      description: t("This can't be undone."),
                      meetingId: meeting.id,
                    })
                  }
                  sx={{ color: "error.main" }}
                >
                  {t("Leave event")}
                </MenuItem>
              )}
          </ContextMenu>
        </Box>
        {meeting.privacyLevel !== PrivacyLevel.Monitored && (
          <Box display="flex" flexDirection="row" gap={2} pb={1}>
            <Tooltip
              title={
                meeting.meetingType === MeetingType.InPersonVisit
                  ? t("This visit is confidential and cannot be monitored.")
                  : featureEnabled("SCREENSHOTS")
                    ? t(
                        "This call is confidential. It cannot be recorded, but low-quality screenshots are taken at random times for staff to review."
                      )
                    : t(
                        "This call is confidential and cannot be recorded or monitored."
                      )
              }
            >
              <LockOutlined />
            </Tooltip>
            <Typography variant="body1" color="text.primary">
              {t("Confidential")}
            </Typography>
          </Box>
        )}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textDecoration: [
              MeetingStatus.Cancelled,
              MeetingStatus.Rejected,
            ].includes(meeting.status)
              ? "line-through"
              : undefined,
          }}
        >
          {formatTimeRange(meeting.interval.startAt, meeting.interval.endAt)}
        </Typography>
        {isWebinarHost && (
          <Typography variant="body2" color="text.secondary">
            {t("{{count}} participants", {
              count: meeting.correspondents.length,
            })}
          </Typography>
        )}
        {(isWebinar ||
          currentUserIsPrimaryVisitor ||
          currentUser.__typename === "Inmate") &&
          isCallJoinable(meeting) && <JoinCallButton meeting={meeting} />}
      </Stack>
    </Card>
  );
}
