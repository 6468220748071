import { groupBy, notMissing } from "@ameelio/core";
import { Avatar, Button, Media } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Connection,
  ConnectionStatus,
  Facility,
  Inmate,
} from "../../api/graphql";
import { appendItem } from "../../client";
import { CreatePendingProfessionalConnectionDocument } from "../../ConnectionsFlow/CreatePendingProfessionalConnection.generated";
import RequestsSent from "../../ConnectionsFlow/RequestsSent.svg";
import ArchiveConnectionButton from "../../Contacts/ArchiveConnectionButton";
import ConnectionStatusChip from "../../Contacts/ConnectionStatusChip";
import ContactRejectedAlert from "../../Contacts/ContactRejectedAlert";
import ContactDetails from "../../Contacts/ContactsDetails";
import { ContentSkeleton } from "../../lib/closet";
import connectionFeatureToFacilityFeature from "../../lib/connectionFeatureToFacilityFeature";
import DetailsStack from "../../lib/DetailsStack";
import useApolloErrorHandler from "../../lib/handleApolloError";
import Link from "../../lib/Link";
import permittedFeaturesList from "../../lib/permittedFeaturesList";
import ScreenHeader from "../../lib/ScreenHeader";
import ScreenSection from "../../lib/ScreenSection";
import SuccessScreen from "../../lib/SuccessScreen";
import { useCurrentMembership, useCurrentVisitor } from "../../SessionBoundary";
import { GetActiveRestrictionsDocument } from "./GetActiveRestrictions.generated";
import { GetContactDetailsDocument } from "./GetContactDetails.generated";

export default function ContactDetailsScreen() {
  const { t } = useTranslation();
  const { id: organizationMembershipId, organization } = useCurrentMembership();
  const currentVisitor = useCurrentVisitor();
  const { inmateId } = useParams<{
    inmateId: string;
  }>();
  if (!inmateId) throw new Error("missing inmateId param");
  // Look for an active connection with this inmate
  const myConnectionId = currentVisitor.connections.find(
    (c) => c.status === ConnectionStatus.Active && c.inmate.id === c.id
  )?.id;

  const { data, error } = useQuery(GetContactDetailsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { inmateId, organizationId: organization.id },
  });
  const handleApolloError = useApolloErrorHandler();

  // Active restrictions can only be queried for the user's connection,
  // so if the provider is viewing the profile of an organization
  // contact (but not a contact of their own), permission errors would be thrown
  // So we skip this if they don't have an active connection with the inmate
  // whose details they're viewing
  const { data: activeRestrictionsData, error: activeRestrictionsError } =
    useQuery(GetActiveRestrictionsDocument, {
      fetchPolicy: "cache-and-network",
      variables: { connectionId: myConnectionId || "" },
      skip: !myConnectionId,
    });

  const [
    showConnectionRequestSuccessPage,
    setShowConnectionRequestSuccessPage,
  ] = useState(false);

  const [
    createPendingProfessionalConnection,
    { loading: isCreateConnectionLoading },
  ] = useMutation(CreatePendingProfessionalConnectionDocument, {
    onError: (e) => handleApolloError(e),
    update: (cache, { data: createConnectionData }) => {
      if (!createConnectionData) return;
      cache.modify({
        id: cache.identify({ __typename: "Visitor", id: currentVisitor.id }),
        fields: {
          connections: appendItem(
            createConnectionData.createPendingProfessionalConnection.connection
          ),
        },
      });
      cache.modify({
        id: cache.identify({ __typename: "Organization", id: organization.id }),
        fields: {
          allConnections: appendItem(
            createConnectionData.createPendingProfessionalConnection.connection
          ),
        },
      });
      setShowConnectionRequestSuccessPage(true);
    },
  });
  const sendConnectionRequest = async () => {
    await createPendingProfessionalConnection({
      variables: {
        input: {
          inmateId,
          organizationMembershipId,
        },
      },
    });
  };

  if (error) throw error;
  if (activeRestrictionsError) throw activeRestrictionsError;
  if (!data || !currentVisitor) return <ContentSkeleton />;

  const { inmate } = data;

  const activeContactsAtOrganization = Object.values(
    groupBy(inmate.connections, (c) => c.visitor.id)
  )
    .filter(notMissing)
    .filter((c) => c[0].status === ConnectionStatus.Active);
  const myConnection = inmate.connections.find(
    (c) => c.visitor.id === currentVisitor.id
  );

  // Parse connection restrictions
  const activeRestrictions =
    activeRestrictionsData?.connection.activeRestrictions?.map(
      (r) => r.service
    ) ?? [];
  const permittedFeatures = permittedFeaturesList({
    features:
      myConnection?.features.map((f) =>
        connectionFeatureToFacilityFeature(f)
      ) ?? [],
    activeRestrictions,
  });

  if (showConnectionRequestSuccessPage)
    return (
      <SuccessScreen
        illustration={RequestsSent}
        illustrationWidth={278}
        title={t("Contact request sent")}
        content={
          <Trans
            t={t}
            defaults="Your request to connect with <bold>{{fullName}}</bold> will be reviewed by facility staff shortly."
            values={{ fullName: inmate.fullName }}
            components={{ bold: <b /> }}
          />
        }
        buttonText={t("Return to contact details")}
        buttonPath={window.location.pathname}
      />
    );

  return (
    <Box pb={5}>
      <ScreenHeader
        enableBackNavigation
        action={
          myConnection?.status === ConnectionStatus.Active ? (
            <Link
              button
              variant="outlined"
              disabled={isCreateConnectionLoading}
              state={{
                connectionId: myConnection.id,
                fromPath: `/organization/${organization.id}/contacts/${inmate.id}`,
                fromName: inmate.fullName,
              }}
              to="/events/new"
              sx={{ flexGrow: 1 }}
            >
              {t("Schedule event")}
            </Link>
          ) : null
        }
      >
        {inmate.fullName}
      </ScreenHeader>
      {!myConnection && (
        <Alert severity="info" sx={{ mb: 4 }}>
          {t(
            "You are not connected to this person, so you cannot message them or schedule meetings with them."
          )}
        </Alert>
      )}
      <ContactDetails
        user={currentVisitor}
        contact={inmate as Inmate}
        connection={myConnection as Connection}
        organization={organization}
        facility={inmate.facility as Facility}
        permittedFeatures={permittedFeatures}
        statusComponent={
          <>
            {!myConnection && !!currentVisitor.identity && (
              <Button
                variant="outlined"
                onClick={() => {
                  sendConnectionRequest();
                }}
              >
                {t("Request to connect")}
              </Button>
            )}
            {!myConnection && !currentVisitor.identity && (
              <Typography variant="body1" color="text.primary">
                {t("Not connected")}
              </Typography>
            )}
            {myConnection?.status === ConnectionStatus.Rejected && (
              <Box flex={1}>
                <ContactRejectedAlert
                  statusDetails={myConnection.statusDetails}
                  submitting={isCreateConnectionLoading}
                  onSubmit={() => sendConnectionRequest()}
                />
              </Box>
            )}
            {myConnection?.status &&
              myConnection.status !== ConnectionStatus.Rejected && (
                <Box>
                  <ConnectionStatusChip status={myConnection.status} />
                </Box>
              )}
          </>
        }
      />
      <ScreenSection
        title={t("{{organizationName}} contacts", {
          organizationName: organization.name,
        })}
      >
        {activeContactsAtOrganization.length === 0 ? (
          <Typography>
            {t("{{firstName}} has no active connections", {
              firstName: inmate.firstName,
            })}
          </Typography>
        ) : (
          <DetailsStack>
            {activeContactsAtOrganization.map((c) => (
              <Link
                sx={{ textDecoration: "none" }}
                key={c[0].id}
                to={`/organization/${organization.id}/team-member/${c[0].organizationMembership?.id}`}
              >
                <Media image={<Avatar contact={c[0].visitor} />}>
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline" }}
                  >
                    {c[0].visitor.fullName}
                  </Typography>
                </Media>
              </Link>
            ))}
          </DetailsStack>
        )}
      </ScreenSection>
      {myConnection && myConnection.status !== ConnectionStatus.Inactive && (
        <Box sx={{ mt: 4 }}>
          <ArchiveConnectionButton
            firstName={inmate.firstName}
            status={myConnection.status}
            connectionId={myConnection.id}
          />
        </Box>
      )}
    </Box>
  );
}
