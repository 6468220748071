import { Button, CheckboxInput, SubmitButton, TextInput } from "@ameelio/ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { sub } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../lib/DateInput";
import {
  AvailableValidator,
  getValidator,
  MAX_USER_AGE,
  validateDateOfBirth,
} from "../lib/validations";
import { TermsPrivacySignUp } from "./TermsPrivacy";

export type ProfileStepData = {
  firstName: string;
  lastName?: string;
  phone: string;
  dateOfBirth?: Date;
  termsOfUse: boolean;
};

type Props = {
  defaultValues: ProfileStepData;
  onBack: () => void;
  onSubmit: (data: ProfileStepData) => void | Promise<void>;
};

// TODO: terms URL
// TODO: privacy policy URL
export default function ProfileForm({
  defaultValues,
  onBack,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProfileStepData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          dateOfBirth: data.dateOfBirth,
          termsOfUse: data.termsOfUse,
        })
      )}
    >
      <Box marginTop={2} marginBottom={6}>
        <Typography variant="body1">
          {t(
            "Finally, please enter your name and date of birth. Security officials will use this later to verify your identity, so be sure it matches your legal ID."
          )}
        </Typography>
      </Box>
      <Stack spacing={2} marginBottom={6}>
        <TextInput
          control={control}
          name="firstName"
          label={t("First name (legal)")}
          rules={{
            required: { value: true, message: t("First name is required") },
          }}
        />
        <TextInput
          control={control}
          name="lastName"
          label={t("Last name (legal)")}
        />
        <TextInput
          control={control}
          name="phone"
          type="tel"
          label={t("Phone number")}
          rules={getValidator(AvailableValidator.PHONE_NUMBER)}
        />
        <DateInput
          control={control}
          name="dateOfBirth"
          label={t("Date of birth")}
          disableFuture
          disableHighlightToday
          minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
          rules={{
            required: { value: true, message: t("Date of birth is required") },
            validate: validateDateOfBirth,
          }}
        />
      </Stack>
      <Box marginBottom={6}>
        <CheckboxInput
          control={control}
          name="termsOfUse"
          label={<TermsPrivacySignUp />}
          rules={{
            validate: (value) => value || t("Please accept the terms of use"),
          }}
        />
      </Box>

      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBackIcon />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton submitting={isSubmitting}>{t("Finish")}</SubmitButton>
      </Grid>
    </form>
  );
}
