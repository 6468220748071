import { Button } from "@ameelio/ui";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomAlertWithButton from "./CustomAlertWithButton";

type Props = {
  title: string;
  onClick: () => void;
};

export default function RejectionAlert({ title, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <CustomAlertWithButton severity="error">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1.5}
      >
        <Typography variant="body1" color="text.primary">
          {title}
        </Typography>
        <Box display="flex" alignItems="center" onClick={onClick}>
          <Button variant="contained">{t("Review")}</Button>
        </Box>
      </Box>
    </CustomAlertWithButton>
  );
}
