import { Tooltip } from "@ameelio/ui";
import {
  Button as MUIButton,
  ButtonProps,
  Link as MUILink,
  LinkProps as MUILinkProps,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

type LinkProps = Omit<
  MUILinkProps<typeof RouterLink, unknown>,
  "component" | "href"
> & {
  button?: undefined;
};

type ButtonLinkProps = Omit<
  ButtonProps<typeof RouterLink, unknown>,
  "component" | "onClick"
> & {
  button: true;
  disabledReason?: string;
};

type Props = LinkProps | ButtonLinkProps;

/**
 * Link is to be used for all internal navigation, whether the result
 * looks like a button or underlined text (default).
 *
 * https://mui.com/material-ui/guides/routing/#link
 */
export default function Link(props: Props) {
  // NOTE: must use delayed destructuring for type narrowing
  // eslint-disable-next-line react/destructuring-assignment
  if (props.button) {
    const { button, sx, disabledReason, disabled, ...rest } = props;

    const inner = (
      <MUIButton
        component={RouterLink}
        {...rest}
        disabled={disabled}
        sx={{ ...(sx || {}), display: "inline-flex" }}
        disableElevation
      />
    );

    // skip the div wrapper when it's not needed. it can break layouts and
    // this minimizes impact until we can find a better way.
    if (!disabled || !disabledReason) return inner;

    return (
      <Tooltip title={disabledReason || ""} disabled={!disabled}>
        <div>{inner}</div>
      </Tooltip>
    );
  }

  const { sx, ...rest } = props;
  return <MUILink component={RouterLink} {...rest} sx={{ ...(sx || {}) }} />;
}

export function ExternalLink(props: MUILinkProps) {
  return <MUILink {...props} />;
}
