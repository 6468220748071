import { useSnackbarContext } from "@ameelio/ui";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type MediaType = "camera" | "microphone" | "screenshare";

const PERMISSION_DENIED_ERRORS = [
  "permission dismissed",
  "permission denied",
  "denied permission",
  "not allowed",
];

export default function useMediaAcquisitionError() {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  // informs the user when there is an error acquiring
  // a media stream and they have not specifically denied
  // permission to that source (i.e. camera, microphone)
  const alertMediaAcquisitionError = useCallback(
    (e: unknown, type: MediaType) => {
      const errorMessage = e instanceof Error ? e.message.toLowerCase() : "";
      // If the user cancels the dialog, the browser treats
      // it as "permission denied" exception
      const permissionDenied = PERMISSION_DENIED_ERRORS.some((s) =>
        errorMessage.includes(s)
      );
      const message = (() => {
        const reloadPage = t("Please reload the page to try again.");
        switch (type) {
          case "camera":
            return permissionDenied
              ? t(
                  "Change your web browser settings to allow Connect to use your camera for this call."
                )
              : `${t("Could not access the camera.")} ${reloadPage}`;
          case "microphone":
            return permissionDenied
              ? t(
                  "Change your web browser settings to allow Connect to use your microphone for this call."
                )
              : `${t("Could not access the microphone.")} ${reloadPage}`;
          case "screenshare":
            return permissionDenied
              ? t(
                  "Change your web browser settings to allow Connect to share your screen for this call."
                )
              : `${t("Could not share screen.")} ${reloadPage}`;
          default:
            throw new Error(`unsupported media acquisition type: ${type}`);
        }
      })();
      // Alert the user to the error
      snackbarContext.alert("error", message);

      if (!permissionDenied)
        // Only send a log to Sentry for unexpected errors,
        // not from canceling the dialog or denying permission
        Sentry.captureMessage(
          `Unexpected error acquiring user media: ${errorMessage}`,
          "error"
        );
    },
    [snackbarContext, t]
  );
  return alertMediaAcquisitionError;
}
