import {
  PasswordInput,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { UserType } from "../api/graphql";
import { useAuthContext } from "../AuthProvider";
import buildPageTitle from "../lib/buildPageTitle";
import { BaseSkeleton } from "../lib/closet";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import { ScreenTitle } from "../lib/typography";
import { GetSystemByCodeDocument } from "./GetSystemByCode.generated";
import { TermsPrivacySignIn } from "./TermsPrivacy";

type FormData = {
  username: string;
  password: string;
};

type InmateLoginProps = {
  code: string;
};

function InmateLogin({ code }: InmateLoginProps) {
  const { t } = useTranslation();

  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      username: import.meta.env.VITE_DEV_INMATE,
      password: import.meta.env.VITE_DEV_PASSWORD,
    },
  });

  const { login } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  // We're defining a list of invalid redirect locations because
  // shared devices could keep meetings information from previous users
  const skipRedirectLocations = ["call"];
  const locationFrom = getFrom(location) || "/";
  const from = skipRedirectLocations.some((r) => locationFrom.match(r))
    ? "/"
    : locationFrom;

  // We shouldn't be here if we haven't selected a system
  if (!code) throw new Error("missing system code");

  const { data, loading, error } = useQuery(GetSystemByCodeDocument, {
    variables: { code: code || "" },
    onError: (e) => handleApolloError(e),
  });

  if (error) throw error;
  if (loading || !data) return <BaseSkeleton />;

  if (!data.systemByCode)
    return <Navigate to="/login-select" state={{ from }} />;

  const onSubmit = async (formData: FormData) => {
    const realUsername = `${formData.username}-${data.systemByCode?.id}`;
    const success = await login(
      realUsername,
      formData.password,
      UserType.Inmate
    );

    if (success) {
      navigate(from, { replace: true });
    } else {
      snackbarContext.alert(
        "error",
        t("Could not log in. Please check your username and password.")
      );
    }
  };

  const pageTitle = buildPageTitle(t("Login"));

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenTitle>{data.systemByCode.name}</ScreenTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ marginY: 6 }}>
          <TextInput
            control={control}
            name="username"
            label={t("Username")}
            rules={{ required: true }}
            autoComplete="off"
            autoCapitalize="false"
            autoFocus
          />
          <PasswordInput
            control={control}
            name="password"
            label={t("Password")}
            rules={{ required: true }}
          />
          <div>
            <Typography variant="caption">
              {t(
                "Please contact a staff member if you are having trouble remembering your login information."
              )}
            </Typography>
          </div>
        </Stack>
        {data.systemByCode.showPPTOS && (
          <Grid container mb={6}>
            <TermsPrivacySignIn />
          </Grid>
        )}
        <Grid container justifyContent="flex-end">
          <SubmitButton submitting={isSubmitting}>{t("Sign in")}</SubmitButton>
        </Grid>
      </form>
    </>
  );
}

export function PathBasedInmateLoginScreen() {
  const { systemCode } = useParams();
  if (systemCode) {
    return <InmateLogin code={systemCode} />;
  }

  return <Navigate to="/login-select" />;
}

export function DomainBasedInmateLoginScreen() {
  const location = useLocation();
  const domain = window.location.host;
  const [domainHeader] = domain.split(".");

  const systemCode = domainHeader.substring(0, domainHeader.lastIndexOf("-"));

  if (systemCode) {
    return <InmateLogin code={systemCode} />;
  }

  const from = getFrom(location) || "/";
  return <Navigate to="/login-select" state={{ from }} />;
}

export default InmateLogin;
