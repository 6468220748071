import React, { useEffect } from "react";

type Props = {
  ref: React.MutableRefObject<HTMLElement | undefined>;
  onBlur: () => void;
};

export default function useBlur({ ref, onBlur }: Props) {
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (
        ref.current &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        onBlur();
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [ref, onBlur]);
}
