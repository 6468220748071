import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SelectInput,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { OrganizationMembershipRole } from "../api/graphql";
import { appendItem } from "../client";
import CopyToClipboard from "../lib/CopyToClipboard";
import useApolloErrorHandler from "../lib/handleApolloError";
import { CreateOrganizationInvitationDocument } from "./CreateOrganizationInvitation.generated";
import { GetOrganizationInvitationCodeDocument } from "./GetOrganizationInvitationCode.generated";

type Props = {
  organizationId: string;
  organizationInvitationId?: string;
  onClose: () => void;
};

type FormData = {
  email: string;
  role: OrganizationMembershipRole | "";
};

export default function InviteMemberDialog({
  organizationId,
  organizationInvitationId,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
      role: "",
    },
  });

  const role = watch("role");
  const roleDescription =
    role === OrganizationMembershipRole.Admin
      ? t(
          "Admins can add incarcerated contacts, set up calls with them, see other members in the organization, and manage organization settings."
        )
      : role === OrganizationMembershipRole.Member
        ? t(
            "Team members can add incarcerated contacts, set up calls with them, and see other members in the organization but cannot manage the organization."
          )
        : "";

  const [invite, setInvite] = useState<{ inviteCode: string; email: string }>();
  const [createInvitation] = useMutation(CreateOrganizationInvitationDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: (data) => {
      const result = data.createOrganizationInvitation.organizationInvitation;
      setInvite({
        inviteCode: result.inviteCode,
        email: result.email,
      });
    },
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: cache.identify({ __typename: "Organization", id: organizationId }),
        fields: {
          invitations: appendItem(
            data.createOrganizationInvitation.organizationInvitation
          ),
        },
      });
    },
  });

  // when given an organizationInvitationId, attempt to recover the invite state
  const { loading: loadingExistingInvitation } = useQuery(
    GetOrganizationInvitationCodeDocument,
    {
      // typescript doesn't support the "skip" pattern
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { organizationInvitationId: organizationInvitationId! },
      skip: !organizationInvitationId,
      onCompleted: (data) => {
        setInvite({
          email: data.organizationInvitation.email,
          inviteCode: data.organizationInvitation.inviteCode,
        });
      },
    }
  );

  const addTeamMember = async (formData: FormData) => {
    if (!formData.role) return;
    await createInvitation({
      variables: {
        input: {
          organizationId,
          email: formData.email,
          role: formData.role,
        },
      },
    });
  };

  return (
    <Dialog title={t("Invite team member")} onClose={onClose}>
      <DialogContent>
        <form onSubmit={handleSubmit(addTeamMember)}>
          <Typography variant="body1" color="text.primary">
            {t(
              "Enter the email of the person you’d like to invite to your organization and their role in the organization"
            )}
          </Typography>

          <Stack spacing={2} my={3}>
            <TextInput
              disabled={loadingExistingInvitation || !!invite}
              control={control}
              name="email"
              type="email"
              label={t("Email")}
              sx={{ width: 1 }}
              rules={{ required: true }}
            />
            <SelectInput
              disabled={loadingExistingInvitation || !!invite}
              control={control}
              name="role"
              label={t("Role")}
              items={[
                {
                  value: OrganizationMembershipRole.Member,
                  name: t("Team member"),
                },
                {
                  value: OrganizationMembershipRole.Admin,
                  name: t("Team admin"),
                },
              ]}
              helperText={roleDescription}
              rules={{ required: true }}
            />
          </Stack>

          {invite ? (
            <Alert
              severity="info"
              sx={{ ".MuiAlert-message": { flexGrow: 1 } }}
            >
              <AlertTitle>
                {t("Copy and send this custom invite link")}
              </AlertTitle>
              <Typography variant="body2" color="text.primary">
                <Trans
                  t={t}
                  defaults="This link will only work for <bold>{{email}}</bold>."
                  values={{ email: invite.email }}
                  components={{ bold: <b /> }}
                />
              </Typography>
              <Box mt={1}>
                <CopyToClipboard
                  value={`${window.location.origin}/invite/${invite.inviteCode}`}
                  buttonCopy={t("Copy link")}
                />
              </Box>
            </Alert>
          ) : loadingExistingInvitation ? (
            <CircularProgress />
          ) : (
            <SubmitButton submitting={isSubmitting} disabled={!isValid}>
              {t("Generate invite link")}
            </SubmitButton>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
