import { Box } from "@mui/material";
import React from "react";
import { BaseSkeleton, ListSkeleton } from "./lib/closet";

export default function AppSkeleton() {
  return (
    <Box sx={{ display: "flex", padding: 3, height: 1 }}>
      <BaseSkeleton variant="rectangular" width={240} sx={{ height: 1 }} />
      <Box component="main" sx={{ flexGrow: 1, marginLeft: 3, paddingTop: 3 }}>
        <ListSkeleton />
      </Box>
    </Box>
  );
}
