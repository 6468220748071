import React from "react";
import { useTranslation } from "react-i18next";
import EmptyScreen from "../EmptyScreen";
import ScreenHeader from "../lib/ScreenHeader";
import { useCurrentCorrespondent } from "../SessionBoundary";
import MessagesComingSoon from "./MessagesComingSoon.svg";

function NoConnectionsScreenCommon({
  contactsLink,
}: {
  contactsLink?: string;
}) {
  const { t } = useTranslation();

  const header = <ScreenHeader>{t("Messages")}</ScreenHeader>;
  const message = t(
    "Once you have approved contacts, this is where you can message with them."
  );
  const illustration = MessagesComingSoon;
  const illustrationWidth = "318";
  if (contactsLink) {
    return (
      <EmptyScreen
        header={header}
        message={message}
        linkProps={{
          label: t("Manage contacts"),
          to: contactsLink,
        }}
        illustration={illustration}
        illustrationWidth={illustrationWidth}
      />
    );
  }
  return (
    <EmptyScreen
      header={header}
      message={message}
      illustration={illustration}
      illustrationWidth={illustrationWidth}
    />
  );
}

function InmateNoConnectionsScreen() {
  return <NoConnectionsScreenCommon />;
}

function VisitorNoConnectionScreen() {
  return <NoConnectionsScreenCommon contactsLink="/contacts" />;
}

export default function NoConnectionsScreen() {
  const user = useCurrentCorrespondent();
  if (user.__typename === "Visitor") {
    return <VisitorNoConnectionScreen />;
  }
  if (user.__typename === "Inmate") {
    return <InmateNoConnectionsScreen />;
  }
  return <div />;
}
