import {
  PasswordInput,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { Grid, Link as RawLink, Stack } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UserType } from "../api/graphql";
import { useAuthContext } from "../AuthProvider";
import buildPageTitle from "../lib/buildPageTitle";
import getFrom from "../lib/getFrom";
import Link from "../lib/Link";
import { ScreenTitle } from "../lib/typography";
import { isEmail } from "../lib/validations";
import { TermsPrivacySignIn } from "./TermsPrivacy";

export const linkStyle = {
  textDecoration: "underline",
  fontSize: "0.83333rem",
  cursor: "pointer",
};

type FormData = {
  email: string;
  password: string;
};

const RESET_PASSWORD_URL = import.meta.env.VITE_RESET_PASSWORD_URL;

function Login() {
  const { t } = useTranslation();

  const snackbarContext = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      email: import.meta.env.VITE_DEV_VISITOR,
      password: import.meta.env.VITE_DEV_PASSWORD,
    },
  });

  const { login } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const from = getFrom(location) || "/";

  const onSubmit = async (data: FormData) => {
    const success = await login(data.email, data.password, UserType.User);
    if (success) {
      navigate(from, { replace: true });
    } else {
      snackbarContext.alert(
        "error",
        t("Could not log in. Please check your username and password.")
      );
    }
  };

  const onForgotPassword = () => {
    if (!RESET_PASSWORD_URL) {
      snackbarContext.alert(
        "error",
        t("Reset password is not enabled for this environment.")
      );
      return;
    }
    window.open(RESET_PASSWORD_URL, "_blank");
  };

  const pageTitle = buildPageTitle(t("Sign in"));

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenTitle>{t("Sign in to Connect")}</ScreenTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ marginY: 6 }}>
          <TextInput
            control={control}
            name="email"
            type="email"
            autoComplete="email"
            label={t("Email address")}
            rules={{
              validate: (value) =>
                isEmail(value) || t("Please enter a valid email address"),
            }}
            autoCapitalize="false"
            autoFocus
          />
          <PasswordInput
            control={control}
            name="password"
            label={t("Password")}
            rules={{
              required: { value: true, message: t("Password is required") },
            }}
          />
          <div>
            <Stack direction="row" spacing={1.5}>
              <RawLink onClick={onForgotPassword} sx={linkStyle}>
                {t("Forgot password?")}
              </RawLink>
              <Link
                to="/support"
                state={{ backTo: "/login", parentName: t("Sign in") }}
                sx={linkStyle}
              >
                {t("Need other support?")}
              </Link>
            </Stack>
          </div>
        </Stack>
        <Grid mb={6}>
          <TermsPrivacySignIn />
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Link button to="/signup" state={{ from }} variant="outlined">
            {t("Create account instead")}
          </Link>
          <SubmitButton submitting={isSubmitting}>{t("Sign in")}</SubmitButton>
        </Grid>
      </form>
    </>
  );
}

export default Login;
