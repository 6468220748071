import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationMembershipFragmentFragmentDoc } from '../api/OrganizationMembershipFragment.generated';
export type CreateOrganizationMembershipMutationVariables = Types.Exact<{
  input: Types.CreateOrganizationMembershipInput;
}>;


export type CreateOrganizationMembershipMutation = { __typename: 'Mutation', createOrganizationMembership: { __typename: 'CreateOrganizationMembershipResponse', organizationMembership: { __typename: 'OrganizationMembership', id: string, role: Types.OrganizationMembershipRole, status: Types.OrganizationMembershipStatus } } };


export const CreateOrganizationMembershipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrganizationMembership"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOrganizationMembershipInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrganizationMembership"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationMembership"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationMembershipFragment"}}]}}]}}]}},...OrganizationMembershipFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateOrganizationMembershipMutation, CreateOrganizationMembershipMutationVariables>;