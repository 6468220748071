import { useSnackbarContext } from "@ameelio/ui";
import * as Sentry from "@sentry/react";
import i18n from "../i18n/i18nConfig";

const ERROR_MESSAGES: Record<string, string> = {
  "The requesting visitor already has a meeting scheduled at this time.":
    i18n.t("You already have a meeting scheduled at this time."), // Tailored to visitor first-person experience
  "User with an active organization membership can't have personal connections.":
    i18n.t(
      "Since you have an active organizational membership, you can't have personal connections with this account. You can set up another account to use with personal connections."
    ),
};

export default function useApolloErrorHandler() {
  const snackbarContext = useSnackbarContext();

  return function handleApolloError(e: unknown) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV) console.error(e);
    if (!(e instanceof Error)) return;

    Sentry.captureException(e, { level: "info" });
    snackbarContext.alert(
      "error",
      e.message in ERROR_MESSAGES ? ERROR_MESSAGES[e.message] : e.message
    );
  };
}
