import {
  Dialog,
  DialogActions,
  DialogContent,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApolloErrorHandler from "../lib/handleApolloError";
import { PrimaryButton, SecondaryButton } from "../lib/ModalButtons";
import { privacyPolicy } from "../lib/staticUrls";
import { useCurrentUser } from "../SessionBoundary";
import { DeleteVisitorDocument } from "./DeleteVisitor.generated";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function DeleteVisitorDialog({ isOpen, onClose }: Props) {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const [deleteVisitor, { loading }] = useMutation(DeleteVisitorDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: () => {
      navigate("/logout");
      snackbarContext.alert("info", t("Your account has been deleted"));
    },
  });

  if (!isOpen) return null;

  return (
    <Dialog title={t("Delete account")} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" color="text.primary">
          {t(
            "Deleting your account is permanent. We will not be able to retrieve it. Please note that in some circumstances, we may retain limited information for a period of time after you delete your account."
          )}
        </Typography>
        <Box my={2}>
          <Link href={privacyPolicy} target="_blank" sx={{ cursor: "pointer" }}>
            <Typography variant="body1">
              {t("Learn more about our Privacy Policy")}
            </Typography>
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          color="error"
          onClick={() =>
            deleteVisitor({
              variables: { input: { visitorId: user.id } },
            })
          }
          disabled={loading}
        >
          {t("Delete account")}
        </SecondaryButton>
        <PrimaryButton onClick={onClose} disabled={loading}>
          {t("Nevermind")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
