import React from "react";
import useAudioLevel from "../../../lib/useAudioLevel";
import {
  AudioActiveFrame,
  AudioActiveIcon,
  AudioMutedIcon,
  AudioToggleButton,
} from "./audioWidgets";

type Props = {
  stream: MediaStream | undefined;
  audioMuted: boolean;
  showAudioControls?: boolean;
  onMute?: () => void;
  onUnmute?: () => void;
  borderRadius?: number | string;
};

export default function ParticipantAudioStatus({
  stream,
  audioMuted,
  showAudioControls,
  onMute,
  onUnmute,
  borderRadius,
}: Props) {
  // measure volume to detect if they are speaking
  // This threshold is NOT a perfect science at the moment :/
  const volumeMeasurement = useAudioLevel({
    stream,
    volumeThreshold: 0.5,
    lowThrottle: 75, // milliseconds
    highThrottle: 200, // milliseconds
  });
  const isSpeaking = volumeMeasurement > 0.5;

  return (
    <>
      {!audioMuted && isSpeaking && (
        <AudioActiveFrame borderRadius={borderRadius} />
      )}
      {showAudioControls && onMute && onUnmute ? (
        <AudioToggleButton
          audioMuted={audioMuted}
          onMute={onMute}
          onUnmute={onUnmute}
        />
      ) : audioMuted ? (
        <AudioMutedIcon />
      ) : isSpeaking ? (
        <AudioActiveIcon />
      ) : null}
    </>
  );
}
