import React from "react";
import { useTranslation } from "react-i18next";
import EmptyScreen, { Props as EmptyScreenProps } from "../EmptyScreen";
import { useCurrentUser } from "../SessionBoundary";
import Events from "./Events.svg";

type Props = {
  cta: "connect" | "schedule" | "none";
};

function EmptyEventsScreen(
  props: Omit<EmptyScreenProps, "illustration" | "illustrationWidth">
) {
  return (
    <EmptyScreen illustration={Events} illustrationWidth="314" {...props} />
  );
}

function VisitorNoEventsScreen({ cta }: Props) {
  const { t } = useTranslation();
  if (cta === "connect") {
    return (
      <EmptyEventsScreen
        message={t(
          "Once your contacts have been approved by the facility, you'll be able to see your scheduled events on this page."
        )}
      />
    );
  }
  if (cta === "schedule") {
    return (
      <EmptyEventsScreen
        message={t(
          "Nothing right now. You can schedule events from the top right."
        )}
      />
    );
  }
  return <EmptyEventsScreen message={t("You have no upcoming events.")} />;
}

export default function NoEventsScreen({ cta }: Props) {
  const user = useCurrentUser();
  const { t } = useTranslation();
  if (user.__typename === "Inmate") {
    return <EmptyEventsScreen message={t("You have no upcoming events.")} />;
  }
  return <VisitorNoEventsScreen cta={cta} />;
}
