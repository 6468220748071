import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../api/ConnectionFragment.generated';
import { CorrespondentFragmentFragmentDoc } from '../api/CorrespondentFragment.generated';
export type GetConnectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConnectionsQuery = { __typename: 'Query', currentCorrespondent: { __typename: 'Inmate', id: string, connections: Array<{ __typename: 'Connection', features: Array<Types.ConnectionFeature>, id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number, contact: { __typename: 'Inmate', canReceiveVoiceCalls: boolean, unavailableUntil: number | null, id: string, firstName: string, lastName: string, fullName: string } | { __typename: 'Visitor', canReceiveVoiceCalls: boolean, unavailableUntil: number | null, id: string, firstName: string, lastName: string, fullName: string }, inmate: { __typename: 'Inmate', id: string, inmateIdentification: string }, activeRestrictions: Array<{ __typename: 'ConnectionRestriction', id: string, service: Types.FacilityService }> }> } | { __typename: 'Visitor', id: string, connections: Array<{ __typename: 'Connection', features: Array<Types.ConnectionFeature>, id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number, contact: { __typename: 'Inmate', canReceiveVoiceCalls: boolean, unavailableUntil: number | null, id: string, firstName: string, lastName: string, fullName: string } | { __typename: 'Visitor', canReceiveVoiceCalls: boolean, unavailableUntil: number | null, id: string, firstName: string, lastName: string, fullName: string }, inmate: { __typename: 'Inmate', id: string, inmateIdentification: string }, activeRestrictions: Array<{ __typename: 'ConnectionRestriction', id: string, service: Types.FacilityService }> }> } | null };


export const GetConnectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConnections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentCorrespondent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"contact"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CorrespondentFragment"}},{"kind":"Field","name":{"kind":"Name","value":"canReceiveVoiceCalls"}},{"kind":"Field","name":{"kind":"Name","value":"unavailableUntil"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inmateIdentification"}}]}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"activeRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"service"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions,...CorrespondentFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetConnectionsQuery, GetConnectionsQueryVariables>;