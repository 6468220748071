import { Tooltip } from "@ameelio/ui";
import { Lock } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PrivacyLevel } from "../../api/graphql";
import CallWidget from "./CallWidget";

export default function CallConfidentialWidget({
  privacyLevel,
}: {
  privacyLevel: PrivacyLevel;
}) {
  const { t } = useTranslation();

  return (
    <CallWidget>
      <Tooltip
        title={
          privacyLevel === PrivacyLevel.Hidden
            ? t(
                "This call is confidential and cannot be recorded or monitored."
              )
            : t(
                "This call is confidential. It cannot be recorded, but low-quality screenshots are taken at random times for staff to review."
              )
        }
      >
        <Lock sx={{ height: 16 }} />
      </Tooltip>
    </CallWidget>
  );
}
