import { Button } from "@ameelio/ui";
import {
  Alert,
  Box,
  Grid,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { common } from "@mui/material/colors";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { track } from "../analytics";
import {
  Inmate,
  Interval,
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
  Visitor,
} from "../api/graphql";
import addStylesForDevices from "../lib/addStylesForDevices";
import { belowLaptop, belowLargeTablet } from "../lib/responsiveHelpers";
import { formatTimeRange } from "../lib/timeFormats";
import useLocalVideo from "../lib/useLocalVideo";
import useNow from "../lib/useNow";
import useOnLoad from "../lib/useOnLoad";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { darkPalette, ltrTheme } from "../theme";
import VideoParticipant from "./LiveCall/VideoParticipant";
import MediaControls from "./MediaControls";
import MeetingParticipantsAccordion from "./MeetingParticipantsAccordion";
import WebinarInfoCard from "./WebinarInfoCard";

type Props = {
  meeting: Pick<
    Meeting,
    "id" | "title" | "privacyLevel" | "meetingType" | "status"
  > & {
    interval: Pick<Interval, "startAt" | "endAt">;
    correspondents: Pick<
      Visitor | Inmate,
      "__typename" | "id" | "firstName" | "lastName" | "fullName"
    >[];
  };
  onJoinButtonClick?: () => void;
  beginMuted: boolean;
  beginHidden: boolean;
  onToggleAudio: () => void;
  onToggleVideo: () => void;
};

type LaptopDesktopScreenProps = {
  videoPreview: React.ReactNode;
  meetingInfo: React.ReactNode;
  controls: React.ReactNode;
};

type MobileTabletScreenProps = Omit<LaptopDesktopScreenProps, "controls">;

function MobileTabletLobby({
  videoPreview,
  meetingInfo,
}: MobileTabletScreenProps) {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: 1,
          width: 1,
          zIndex: -1,
          backgroundColor: darkPalette.background.default,
        }}
      >
        {videoPreview}
      </Box>
      <Grid
        container
        spacing={1}
        pt={2}
        pb={0}
        pr={2}
        pl={1}
        margin={0}
        sx={{
          height: 1,
          width: 1,
          overflow: "auto",
          backgroundColor: "rgba(0,0,0,0.75)",
        }}
      >
        {meetingInfo}
      </Grid>
    </>
  );
}

function LaptopDesktopLobby({
  videoPreview,
  meetingInfo,
  controls,
}: LaptopDesktopScreenProps) {
  return (
    <Grid
      container
      spacing={5}
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        width: 1,
        margin: 0,
        pr: { xs: 0, md: 5 },
      }}
    >
      <Grid
        item
        xs={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {videoPreview}
        {controls}
      </Grid>
      {meetingInfo}
    </Grid>
  );
}

function PrivacyLevelStatement({
  privacyLevel,
  isWebinar,
}: {
  privacyLevel: PrivacyLevel;
  isWebinar: boolean;
}) {
  const { t } = useTranslation();
  if (privacyLevel === PrivacyLevel.Monitored) {
    return (
      <Typography
        variant="body2"
        sx={{ marginBottom: 3 }}
        color={{ xs: common.white, md: "text.secondary" }}
      >
        {isWebinar
          ? t(
              "By participating in this call, I consent to the potential monitoring of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
            )
          : t(
              "By participating in this call, I consent to the potential monitoring and recording of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
            )}
      </Typography>
    );
  }
  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      {t(
        "This call is confidential and will not be recorded. For the purpose of institutional security, to ensure that only approved visitors are present during the call, several low-quality screenshots will be taken at random times during the call. Select facility staff members will be able to view and download these screenshots for participant verification purposes only. You will also be able to view these screenshots on this event’s details page."
      )}
    </Alert>
  );
}

export default function Lobby({
  meeting,
  onJoinButtonClick,
  beginMuted,
  beginHidden,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const navigate = useNavigate();

  const meetingHasEnded = [
    MeetingStatus.Ended,
    MeetingStatus.Terminated,
    MeetingStatus.NoShow,
  ].includes(meeting.status);

  const isWebinar = meeting.meetingType === MeetingType.Webinar;
  const isHost = user.__typename === "Visitor";
  const isWebinarAttendee = isWebinar && !isHost;

  const isBelowLaptop = belowLaptop(measureScreenWidth);
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  const userMedia = useLocalVideo();

  const [participantsAccordionExpanded, setParticipantsAccordionExpanded] =
    useState<boolean>(false);

  const handleParticipantsAccordionChange = useCallback(
    (expanded: boolean) => {
      setParticipantsAccordionExpanded(expanded);
    },
    [setParticipantsAccordionExpanded]
  );

  const now = useNow({ updateDelay: 15 * 1000 });
  const isBeforeScheduledStart = now.getTime() < meeting.interval.startAt;
  const isAfterScheduledEnd = now.getTime() > meeting.interval.endAt;

  const lobbyStatus =
    meetingHasEnded || isAfterScheduledEnd
      ? "ended"
      : isBeforeScheduledStart
        ? "early"
        : onJoinButtonClick
          ? "ready"
          : "loading";

  useOnLoad(() => {
    track("Call - Lobby", {
      meetingType: meeting.meetingType,
      lobbyStatus,
    });
  });

  // Sub-components common to Mobile/Tablet and Laptop/Desktop
  const controls = (
    <Box
      sx={{
        py: { xs: 3, md: 2 },
        "> span": {
          my: 0,
          mx: 1,
        },
      }}
    >
      <MediaControls
        controlBkgLight
        micDisabled={isWebinarAttendee}
        micDisabledHelperText={t("Only hosts can unmute you")}
        hidden={beginHidden}
        muted={beginMuted}
        {...rest}
        onToggleVideo={isWebinarAttendee ? undefined : rest.onToggleVideo}
      />
    </Box>
  );

  const meetingInfo = (
    <Grid item xs={12} md={5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          justifyContent: { xs: "flex-end", md: "center" },
          paddingBottom: { xs: ltrTheme.spacing(2), md: ltrTheme.spacing(5) },
        }}
      >
        <Typography
          variant="h2"
          mb={0.5}
          color={{ xs: common.white, md: "text.primary" }}
          component="span"
        >
          {meeting.title ||
            meeting.correspondents
              .filter((c) => c.__typename !== user.__typename)
              .map((c) => c.fullName)
              .join(", ")}
        </Typography>
        <Typography
          variant="subtitle1"
          mb={3}
          color={{ xs: common.white, md: "text.secondary" }}
        >
          {formatTimeRange(meeting.interval.startAt, meeting.interval.endAt)}
        </Typography>
        {isWebinar && (
          <>
            {isHost && (
              <Box
                sx={{
                  maxHeight: "200px",
                  overflow: "auto",
                  mb: 3,
                  pl: 1.5,
                  backgroundColor: "inherit",
                  ...(participantsAccordionExpanded
                    ? {
                        borderBottom: 1,
                        borderBottomColor: isMobileOrSmallTablet
                          ? common.white
                          : "divider",
                      }
                    : {}),
                }}
              >
                <MeetingParticipantsAccordion
                  meeting={meeting}
                  sx={{
                    color: isMobileOrSmallTablet
                      ? common.white
                      : "text.primary",
                    backgroundColor: "inherit",
                  }}
                  expandIconSx={{
                    color: isMobileOrSmallTablet
                      ? common.white
                      : "action.active",
                  }}
                  textColor={{
                    primary: isMobileOrSmallTablet
                      ? common.white
                      : "text.primary",
                    secondary: isMobileOrSmallTablet
                      ? common.white
                      : "text.secondary",
                  }}
                  onChange={handleParticipantsAccordionChange}
                />
              </Box>
            )}
            <WebinarInfoCard
              isHost={isHost}
              sx={addStylesForDevices(
                isMobileOrSmallTablet,
                { mb: 3 },
                {
                  backgroundColor: "inherit",
                  borderColor: common.white,
                  color: common.white,
                }
              )}
              textColor={isMobileOrSmallTablet ? common.white : "text.primary"}
            />
          </>
        )}
        {lobbyStatus === "ended" ? (
          <>
            <Box marginBottom={3}>
              <Alert severity="error">{t("This call has ended.")}</Alert>
            </Box>
            <Box marginBottom={3}>
              <Button variant="contained" onClick={() => navigate("/")}>
                {t("Return home")}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <PrivacyLevelStatement
              privacyLevel={meeting.privacyLevel}
              isWebinar={isWebinar}
            />
            {lobbyStatus === "early" ? (
              <Box marginBottom={3}>
                <Alert severity="warning">
                  {t(
                    "This call has not started yet. You will be able to join during the scheduled time."
                  )}
                </Alert>
              </Box>
            ) : lobbyStatus === "ready" ? (
              <Button
                variant="contained"
                onClick={onJoinButtonClick}
                sx={addStylesForDevices(isBelowLaptop, {}, { width: 1 })}
              >
                {isWebinar
                  ? isHost
                    ? t("Start class")
                    : t("Join class")
                  : t("Join call")}
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                sx={addStylesForDevices(
                  isMobileOrSmallTablet,
                  {},
                  {
                    width: 1,
                    backgroundColor: `${darkPalette.action.disabled}!important`,
                    color: `${common.white}!important`,
                  }
                )}
              >
                {t("Loading")}
              </Button>
            )}
          </>
        )}
        {isMobileOrSmallTablet && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {controls}
          </Box>
        )}
      </Box>
    </Grid>
  );

  const videoPreview = userMedia ? (
    <VideoParticipant
      user={user}
      audioStream={undefined}
      stream={userMedia}
      audioMuted={beginMuted}
      videoPaused={beginHidden}
      origin="local"
      mirrored
      containerSx={addStylesForDevices(
        isMobileOrSmallTablet,
        { borderRadius: 4 },
        { borderRadius: 0 }
      )}
      transitionDuration={0}
    />
  ) : undefined;

  if (isMobileOrSmallTablet) {
    return (
      <MobileTabletLobby
        meetingInfo={meetingInfo}
        videoPreview={videoPreview}
      />
    );
  }

  return (
    <LaptopDesktopLobby
      meetingInfo={meetingInfo}
      videoPreview={videoPreview}
      controls={controls}
    />
  );
}
