import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  dueDate: string;
};

export default function ProvideYourIdReminder({ dueDate }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Alert
      severity="warning"
      variant="outlined"
      sx={{ mt: 1, mb: 2, alignItems: "flex-start", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <AlertTitle>{t("Provide your ID for approval")}</AlertTitle>
        <Typography variant="body2">
          {t("Upload your ID by {{dueDate}} to be approved for visits.", {
            dueDate,
          })}
        </Typography>
        <Box sx={{ flexDirection: "row", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/settings/identification");
            }}
          >
            {t("Upload state ID")}
          </Button>
        </Box>
      </Box>
    </Alert>
  );
}
