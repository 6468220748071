import { uniq } from "@ameelio/core";
import {
  Accordion,
  Avatar,
  Button,
  Media,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { PeopleAltOutlined, PresentToAllOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { track } from "../../analytics";
import { Interval, Kiosk, MeetingStatus, MeetingType } from "../../api/graphql";
import useApolloErrorHandler from "../../lib/handleApolloError";
import MeetingTypeIcon from "../../lib/MeetingTypeIcon";
import meetingTypeLabel from "../../lib/meetingTypeLabel";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { formatTimeRange, relativeDate } from "../../lib/timeFormats";
import { ScreenTitle } from "../../lib/typography";
import uniqBy from "../../lib/uniqBy";
import withSx from "../../lib/withSx";
import { useCurrentVisitor } from "../../SessionBoundary";
import { ScheduleMeetingDocument } from "./ScheduleMeeting.generated";
import { InmateConnection, RegisteredGuest, UnregisteredGuest } from "./types";
import { UpdateMeetingDocument } from "./UpdateMeeting.generated";
import { Step } from "./useWorkflowSteps";

type Props = {
  stepper: StepperType;
  availability: {
    interval: Pick<Interval, "startAt" | "endAt">;
    availableKiosks?: Pick<Kiosk, "id" | "capacity">[];
  };
  kiosk?: Pick<Kiosk, "id">;
  onSubmit: (arg: MeetingStatus) => void;
  meetingId?: string;
  title?: string | null;
  meetingType: MeetingType;
  connections: InmateConnection[];
  registeredGuests?: RegisteredGuest[];
  unregisteredGuests?: UnregisteredGuest[];
  onEdit: (step: Step) => void;
  willBeConfidential?: boolean;
};

const DetailsBox = withSx(Box, {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  py: 1,
});

function Datum({
  icon,
  children,
}: {
  icon: JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <Media image={icon}>
      <Typography variant="subtitle1">{children}</Typography>
    </Media>
  );
}

export default function ConfirmStep({
  stepper,
  availability,
  kiosk,
  onSubmit,
  meetingId,
  title,
  meetingType,
  connections,
  registeredGuests,
  unregisteredGuests,
  onEdit,
  willBeConfidential,
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const currentVisitor = useCurrentVisitor();
  const onError = useApolloErrorHandler();

  // Create mutation
  const [scheduleMeeting, { loading: scheduleLoading }] = useMutation(
    ScheduleMeetingDocument,
    {
      onError: (e) => {
        onError(e);
        track("Meeting request - Failure", {
          reason: e instanceof Error ? e.message : "unknown",
          action: "create",
        });
      },
      update: (cache, { data: scheduleMeetingData }) => {
        if (!scheduleMeetingData) return;
        cache.modify({
          id: cache.identify({
            __typename: "Visitor",
            id: currentVisitor.id,
          }),
          fields: {
            meetings(existing) {
              return {
                edges: [
                  ...existing.edges,
                  {
                    node: {
                      meeting:
                        scheduleMeetingData.createScheduledMeeting.meeting,
                    },
                  },
                ],
              };
            },
          },
        });
      },
      onCompleted: (d) => {
        const { status } = d.createScheduledMeeting.meeting;
        track("Meeting request - Success", {
          meetingType,
          action: "create",
          facilityId: connections[0].inmate.facility.id,
          groupSize:
            (registeredGuests?.length || 0) +
            (unregisteredGuests?.length || 0) +
            1,
        });
        onSubmit(status);
      },
    }
  );

  // Update mutation
  const [updateMeeting, { loading: updateLoading }] = useMutation(
    UpdateMeetingDocument,
    {
      onError: (e) => {
        onError(e);
        track("Meeting request - Failure", {
          reason: e instanceof Error ? e.message : "unknown",
          action: "update",
        });
      },
      update: (cache, { data: scheduleMeetingData }) => {
        if (!scheduleMeetingData) return;
        cache.modify({
          id: cache.identify({
            __typename: "Visitor",
            id: currentVisitor.id,
          }),
          fields: {
            meetings(existing) {
              return {
                edges: [
                  ...existing.edges,
                  {
                    node: {
                      meeting:
                        scheduleMeetingData.updateScheduledMeeting.meeting,
                    },
                  },
                ],
              };
            },
          },
        });
      },
      onCompleted: (d) => {
        const { status } = d.updateScheduledMeeting.meeting;
        track("Meeting request - Success", {
          meetingType,
          action: "update",
          facilityId: connections[0].inmate.facility.id,
          groupSize:
            (registeredGuests?.length || 0) +
            (unregisteredGuests?.length || 0) +
            1,
        });
        onSubmit(status);
      },
    }
  );
  const unregisteredGuestNames = unregisteredGuests?.map((urg) => urg.name);
  const guests = [
    ...(registeredGuests?.map((rg) => rg.fullName) || []),
    ...(unregisteredGuestNames || []),
  ];

  return (
    <StepperFormWrapper
      stepper={stepper({ loading: scheduleLoading || updateLoading })}
      handleSubmit={(event) => {
        event.preventDefault();

        const facilityId = connections[0].inmate.facility?.id;
        const baseData = {
          title: title || undefined,
          connectionIds: [
            ...connections
              .filter(
                // Registered guests will always be populated below, filter
                // the existing connections to be only those of the primary scheduler
                (c) => c.visitor.id === currentVisitor.id
              )
              .map((c) => c.id),
            ...(registeredGuests?.map((rg) => rg.connectionId) || []),
          ],
          unregisteredGuests: unregisteredGuestNames,
          scheduledStart: availability.interval.startAt,
          scheduledEnd: availability.interval.endAt,
        };

        // this is required because of how the Partial works
        // where kiosk cannot be a required field (shouldn't happen)
        if (meetingType !== MeetingType.Webinar && !kiosk) {
          return;
        }

        // If the number of requested co-hosts multiplied by the number of
        // requested students doesn't equal the number of connections
        // between those co-hosts and students, it means that not everyone
        // is connected.
        if (meetingType === MeetingType.Webinar) {
          // Construct all connection ids from host and co-host connections
          const studentIds = uniq(connections.map((c) => c.inmate.id));
          const hostIds = uniq(connections.map((c) => c.visitor.id));

          if (studentIds.length * hostIds.length !== connections.length) {
            snackbarContext.alert(
              "error",
              t("Every co-host and student must be connected")
            );
            return;
          }
        }

        if (meetingId) {
          updateMeeting({
            variables: {
              input: {
                ...baseData,
                meetingId,
              },
            },
          });
        } else {
          scheduleMeeting({
            variables: {
              input: {
                ...baseData,
                facilityId,
                // kiosk should only be provided for non-webinar meetings,
                // and there is a check it exists above
                kioskId: kiosk?.id,
                meetingType,
              },
            },
          });
        }
      }}
    >
      <ScreenTitle>{t("Does everything look good?")}</ScreenTitle>
      <Typography marginTop={2}>
        {t(
          "Participants with registered accounts will be notified once the event is confirmed."
        )}
      </Typography>

      <Box marginY={6}>
        <Card sx={{ px: 1.5, py: 1 }}>
          {meetingType === MeetingType.Webinar ? (
            <DetailsBox>
              <Accordion
                title={title || ""}
                titleImage={<PresentToAllOutlined />}
                numItems={
                  uniqBy(
                    connections.map((c) => c.visitor),
                    "id"
                  ).filter((v) => v.id !== currentVisitor.id).length +
                  uniqBy(
                    connections.map((c) => c.inmate),
                    "id"
                  ).length
                }
                details={
                  <>
                    {uniqBy(
                      connections.map((c) => c.visitor),
                      "id"
                    )
                      .filter((v) => v.id !== currentVisitor.id)
                      .map((visitor) => (
                        <Box key={visitor.id} p={1} pl={5}>
                          <Media image={<Avatar contact={visitor} />}>
                            <Typography
                              variant="subtitle2"
                              color="text.primary"
                            >
                              {visitor.fullName} {t("(co-host)")}
                            </Typography>
                          </Media>
                        </Box>
                      ))}
                    {uniqBy(
                      connections.map((c) => c.inmate),
                      "id"
                    ).map((inmate) => (
                      <Box key={inmate.id} p={1} pl={5}>
                        <Media image={<Avatar contact={inmate} />}>
                          <Typography variant="subtitle2" color="text.primary">
                            {inmate.fullName}
                          </Typography>
                        </Media>
                      </Box>
                    ))}
                  </>
                }
                sx={{ flexGrow: 0 }}
              />
              <Button
                sx={{ p: 0, textDecoration: "underline" }}
                variant="text"
                color="primary"
                size="small"
                onClick={() => onEdit("selectWho")}
              >
                {t("Edit")}
              </Button>
            </DetailsBox>
          ) : (
            <>
              <DetailsBox>
                <Datum icon={<MeetingTypeIcon meetingType={meetingType} />}>
                  {willBeConfidential
                    ? `${t("Confidential")} ${meetingTypeLabel(
                        meetingType
                      ).toLocaleLowerCase()}`
                    : meetingTypeLabel(meetingType)}
                </Datum>

                <Button
                  sx={{ p: 0, textDecoration: "underline" }}
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => onEdit("selectEventType")}
                >
                  {t("Edit")}
                </Button>
              </DetailsBox>
              <DetailsBox>
                <Datum icon={<PersonIcon />}>
                  {connections[0].inmate.fullName}
                </Datum>

                <Button
                  sx={{ p: 0, textDecoration: "underline" }}
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => onEdit("selectWho")}
                >
                  {t("Edit")}
                </Button>
              </DetailsBox>
              {guests.length > 0 && (
                <DetailsBox>
                  <Accordion
                    title={t("Guest list")}
                    titleImage={<PeopleAltOutlined />}
                    numItems={guests.length}
                    details={
                      <>
                        {guests.map((guestName, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Box key={`${guestName}_${index}`} p={1} pl={5}>
                            <Media
                              image={
                                <Avatar contact={{ fullName: guestName }} />
                              }
                            >
                              <Typography
                                variant="subtitle2"
                                color="text.primary"
                              >
                                {guestName}
                              </Typography>
                            </Media>
                          </Box>
                        ))}
                      </>
                    }
                    sx={{ flexGrow: 0 }}
                  />
                  <Button
                    sx={{ p: 0, textDecoration: "underline" }}
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => onEdit("addGuests")}
                  >
                    {t("Edit")}
                  </Button>
                </DetailsBox>
              )}
            </>
          )}
          <DetailsBox>
            <Datum icon={<EventIcon />}>
              {relativeDate(availability.interval.startAt)}
            </Datum>
            <Button
              sx={{ p: 0, textDecoration: "underline" }}
              variant="text"
              color="primary"
              size="small"
              onClick={() => onEdit("selectWhen")}
            >
              {t("Edit")}
            </Button>
          </DetailsBox>
          <DetailsBox>
            <Datum icon={<AccessTimeIcon />}>
              {formatTimeRange(
                availability.interval.startAt,
                availability.interval.endAt
              )}
            </Datum>
            <Button
              sx={{ p: 0, textDecoration: "underline" }}
              variant="text"
              color="primary"
              size="small"
              onClick={() => onEdit("selectWhen")}
            >
              {t("Edit")}
            </Button>
          </DetailsBox>
        </Card>
      </Box>
    </StepperFormWrapper>
  );
}
