import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import { ArchiveConnectionDocument } from "./ArchiveConnection.generated";

type Props = {
  firstName: string;
  status?: ConnectionStatus;
  connectionId: string;
  onClose: () => void;
};

export default function ConfirmArchiveConnectionDialog({
  firstName,
  status,
  connectionId,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const [archiveConnection] = useMutation(ArchiveConnectionDocument, {
    variables: {
      input: {
        connectionId,
      },
    },
    onError: (e) => handleApolloError(e),
    onCompleted: onClose,
  });

  const activeDescription =
    status === ConnectionStatus.Active
      ? `${t(
          "This will cancel any upcoming events you have scheduled with {{firstName}}.",
          { firstName }
        )}`
      : "";

  return (
    <ConfirmDialog
      title={t("Are you sure you want to archive this contact?")}
      description={`${activeDescription} ${t(
        "If you wish to meet with them in the future, you must submit a new contact request and be approved."
      )}`}
      onConfirm={() => {
        archiveConnection();
      }}
      onCancel={onClose}
    />
  );
}
