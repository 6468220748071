import { sortByStrings } from "@ameelio/core";
import { SelectInput, TextInput } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import buildPageTitle from "../lib/buildPageTitle";
import { AvailableCountry, getCountryLabel } from "../lib/DocumentType";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";
import { useSelectableCountry } from "../SessionBoundary";
import ConnectionsFlowWarning from "./ConnectionsFlowWarning";
import { FacilitiesMap, SearchCriteria, SimplifiedFacility } from "./types";

type Props = {
  stepper: StepperType;
  selectedCountry: AvailableCountry;
  selectedProvince: string;
  facilitiesMap: FacilitiesMap;
  onSubmit: (data: SearchCriteria) => void | Promise<void>;
};

const filterFacilitiesByProvince = (
  facilities: SimplifiedFacility[],
  province?: string
): SimplifiedFacility[] =>
  province ? facilities.filter((f) => f.province === province) : facilities;

export default function ConnectionsSearchForm({
  stepper,
  selectedCountry,
  selectedProvince,
  facilitiesMap,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const defaultCountry = useSelectableCountry();

  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting, isValid },
    watch,
    setValue,
  } = useForm<SearchCriteria>({
    mode: "onChange",
    defaultValues: {
      province: selectedProvince,
      country: selectedCountry || defaultCountry,
    },
  });

  const currentCountry = watch("country");
  const currentProvince = watch("province");

  const availableProvinces = facilitiesMap[currentCountry]?.provinces.sort();

  useEffect(() => {
    // Set province in specific cases.
    // The country has a single province. Set it as default choice.
    if (
      availableProvinces?.length === 1 &&
      availableProvinces[0] !== currentProvince
    ) {
      setValue("province", availableProvinces[0]);
    }
    // The country does not have the currently selected province. Reset the value of the province
    // It can happen when the user has just changed the country.
    if (currentProvince && !availableProvinces.includes(currentProvince)) {
      setValue("province", "");
    }
  }, [currentProvince, availableProvinces, setValue]);

  // when province changes, reset the selected facility
  useEffect(() => {
    setValue("facilityId", undefined);
  }, [currentProvince, setValue]);

  const availableFacilities = sortByStrings(
    filterFacilitiesByProvince(
      facilitiesMap[currentCountry]?.facilities,
      currentProvince
    ),
    (r) => r.fullName
  );

  const pageTitle = buildPageTitle(t("Request contacts"));

  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: isSubmitting,
        disabled: !isValid,
      })}
      handleSubmit={handleFormSubmit((data) => onSubmit(data))}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenTitle>{t("Let's find who you're looking for")}</ScreenTitle>
      <Stack marginTop={3} marginBottom={6} spacing={3}>
        <>
          <Stack spacing={1}>
            <Typography variant="overline">{t("Required")}</Typography>
            <TextInput
              name="lastNameOrInmateId"
              control={control}
              label={
                currentCountry === AvailableCountry.US
                  ? t("ID Number (recommended) or Last Name*")
                  : t("ID Number")
              }
              rules={{
                required: {
                  value: true,
                  message:
                    currentCountry === AvailableCountry.US
                      ? t("ID Number or Last Name is required")
                      : t("ID Number is required"),
                },
              }}
            />
          </Stack>
          <Typography variant="overline">{t("Optional")}</Typography>
          {availableProvinces?.length > 1 && (
            <Box marginY={6}>
              <SelectInput
                name="province"
                label={
                  currentCountry === AvailableCountry.US
                    ? t("State")
                    : t("Province")
                }
                items={availableProvinces.map((s) => ({
                  name: s,
                  value: s,
                }))}
                control={control}
              />
            </Box>
          )}
          {availableFacilities?.length > 0 && (
            <Stack spacing={1}>
              <SelectInput
                control={control}
                name="facilityId"
                label={t("Facility")}
                items={availableFacilities?.map((f) => ({
                  name: f.name || f.fullName || "",
                  value: f.id,
                }))}
              />
            </Stack>
          )}
        </>
        <SelectInput
          items={Object.keys(facilitiesMap).map((v) => ({
            value: v,
            name: getCountryLabel(v as AvailableCountry),
          }))}
          name="country"
          label={t("Country")}
          control={control}
          rules={{ validate: (v) => !!v }}
        />
        {currentCountry === AvailableCountry.US && currentProvince && (
          <Box>
            <Typography marginTop={2} color="text.secondary">
              <ConnectionsFlowWarning
                province={currentProvince}
                defaultMessage={t(
                  "*An ID number identifies a specific incarcerated person independent of the facility they are currently located in. It can take a few different formats depending on when and where they were first incarcerated. {{stateName}} provides the following resource to locate this number if you do not know it: <resourceLink>{{stateName}} {{resourceName}}</resourceLink>"
                )}
              />
            </Typography>
          </Box>
        )}
      </Stack>
    </StepperFormWrapper>
  );
}
